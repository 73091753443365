/* eslint-disable react/jsx-no-literals,react/no-unescaped-entities,max-len */
import React from 'react';

const EnglishText: React.FC = () => (
  <>
    <h2>GENERAL TERMS AND CONDITIONS OF USE OF ONOFF Business</h2>

    <h3>1. Definitions</h3>

    <p>
      The following terms in the Terms of Use have, unless otherwise specified, the following meanings:
    </p>

    <p>
      <strong>Operator Subscription:</strong>
      { ' ' }
      means the contract for mobile telephony and mobile broadband Internet services signed by
      the User or by the Company with a third-party operator prior to the use of the Services and independently of ONOFF
      TELECOM.
    </p>

    <p>
      <strong>Application:</strong>
      { ' ' }
      means any application (including the software called ONOFF Business), developed and operated by ONOFF
      TELECOM, downloadable on application distribution platforms and allowing the User to benefit from the Services.
      The term Application also refers to the solution allowing the use of Numbers through applications belonging to
      third parties (Microsoft Teams or Zoom).
    </p>

    <p>
      <strong>Employee:</strong>
      { ' ' }
      refers to one of the employees (employee or corporate representative) of the Company.
    </p>

    <p>
      <strong>Company Account:</strong>
      { ' ' }
      refers to the account created by the Company in order to define its Employees and, for each of
      them, the type of Services from which it should benefit.
    </p>

    <p>
      <strong>User Account:</strong>
      { ' ' }
      refers to the Account of the Employee, created by the Manager, and allowing the Employee to use the
      Application.
    </p>

    <p>
      <strong>Terms of Use:</strong>
      { ' ' }
      refers to these general conditions of use governing, together with the Personal Data Protection
      Policy and the Fee Schedule, the contractual relations relating to the provision of the Services by ONOFF TELECOM
      to the Company.
    </p>

    <p>
      <strong>Management Console:</strong>
      { ' ' }
      refers to the tools made available to the Company, via the Website, and allowing a Manager to
      (1) create the Company Account, (2) list all the Employees likely to benefit from the Services, (3) give them
      effective access to the Services concerned and (4) subsequently manage the access of these Users to the Services.
    </p>

    <p>
      <strong>User Content:</strong>
      { ' ' }
      means any content posted by the User through the Application.
    </p>

    <p>
      <strong>Contract:</strong>
      { ' ' }
      refers to the contractual set consisting of the Conditions of Use, the Fee Schedule and the Personal
      Data Protection Policy between ONOFF TELECOM and the Company.
    </p>

    <p>
      <strong>ONOFF Credits:</strong>
      { ' ' }
      refers to credits used to purchase Services, including making phone calls and/or sending SMS.
    </p>

    <p>
      <strong>Company:</strong>
      { ' ' }
      means any legal person or any natural person acting as a professional, registered in the Trade and
      Companies Register or in the Trades Register, as well as any public authority or association, likely to open an
      ONOFF Business Company Account in order to allow its Employees to use the Application.
    </p>

    <p>
      <strong>Fee Schedule:</strong>
      { ' ' }
      means the fee schedule applicable to the Services and available at the address
      https://onoffbusiness.com/pricing.
    </p>

    <p>
      <strong>Manager:</strong>
      { ' ' }
      refers to an Employee who has the administrative rights of the Company Account and the rights to access
      the Management Console.
    </p>

    <p>
      <strong>Number(s):</strong>
      { ' ' }
      refers to the mobile telephone number(s) of the national numbering plan assigned to one or more User(s)
      by means of the Management Console.
    </p>

    <p>
      <strong>ONOFF TELECOM:</strong>
      { ' ' }
      refers to the company ONOFF TELECOM, a simplified joint-stock company, registered in the Paris
      Trade and Companies Register under the number 832 235 253 and having its registered office at 26 boulevard de
      Bonne Nouvelle 75010, Paris, France, exercising an activity of operator in the field of telecommunications.
    </p>

    <p>
      <strong>Personal Data Protection Policy:</strong>
      { ' ' }
      refers to the personal data protection policy defined by ONOFF TELECOM and
      available at https://onoffbusiness.com/privacy-policy .
    </p>

    <p>
      <strong>Service(s):</strong>
      { ' ' }
      refers to all secondary contact management and communication services, paid or free, offered by ONOFF
      TELECOM and accessible from mobile access by the User via the Application in accordance with the Terms of Use.
    </p>

    <p>
      <strong>Website:</strong>
      { ' ' }
      means the site accessible at the address onoffbusiness.com, operated by ONOFF TELECOM.
    </p>

    <p>
      <strong>User:</strong>
      { ' ' }
      means the holder of a User Account and the holder of a license to use the Application in accordance with the
      Terms of Use.
    </p>

    <h3>2. Purpose of the Terms of Use</h3>

    <p>
      The Terms of Use apply to the Services marketed and operated by ONOFF TELECOM and are intended to define the
      practical and financial conditions under which the Company will be able to (i) create its Company account, (ii)
      list all Employees likely to benefit from the Services, (iii) give them effective access to the Services concerned
      and (iv) subsequently manage the access of these Employees to the Services.
    </p>

    <p>
      ONOFF TELECOM may, by operation of law, modify its service offers and/or make changes to the Terms of Use without
      any other formality than to inform the Company in advance by any means at its convenience. In the event that the
      Company does not wish to accept the application of the new Terms of Use, it may notify its wish to terminate the
      Contract concluded with ONOFF TELECOM. The fact that the Company does not terminate the Contract will carry its
      unreserved acceptance of the new version of the Terms of Use. No agreement, general conditions of purchase or
      commitment may, unless formally and writtenly accepted by ONOFF TELECOM, prevail over the Terms of Use. Any clause
      to the contrary will, in the absence of express acceptance, be unenforceable against ONOFF TELECOM, regardless of
      when it may have been brought to the latter's attention. Only the Conditions of Use, the Fee Schedule and the
      Personal Data Protection Policy constitute the contractual documents between ONOFF TELECOM and the Company; the
      other subsequent documents will formalize the necessary steps of the execution of the Services and their
      follow-up.
    </p>

    <p>
      Access to the Services is subject to prior acceptance of the Terms of Use. The Company will accept the Terms of
      Use (i) by clicking to accept the Terms of Use or (ii) by creating its Company Account.
    </p>

    <h3>3. Company Account</h3>

    <p>
      When creating the Company Account through the Management Console, the Company provides a certain amount of
      information that may include its company name, address, nationality, registration number in the trade and
      companies register or in the trade register, its intra-EU community VAT number. The Company undertakes to provide
      the identity and identification documents of its legal representative at the request of ONOFF TELECOM. The Company
      undertakes to provide truthful information.
    </p>

    <p>
      In addition, the Company also communicates to ONOFF TELECOM the surname, first name, telephone number and email
      address of the company account manager (the "
      <strong>Manager</strong>
      "), and defines a password to secure access to the Company
      Account.
    </p>

    <p>
      ONOFF TELECOM reserves the right to request and obtain (i) a register extract of less than three months or any
      document containing the legally mandatory information for the designation of the legal person, (ii) an official
      document attesting to the capacity of the signatory and the extent of his powers and (iii) proof of identity of
      the natural person duly authorized to subscribe to the Contract on behalf of the Company. ONOFF TELECOM reserves
      the right to request and obtain any other document or information it deems necessary in addition and, in
      particular for Users, a proof of identity and/or domicile or the mobile number identifying the mobile access
      available to the User.
    </p>

    <p>
      ONOFF TELECOM reserves the right to delete at any time a Company Account that does not comply with the applicable
      legal provisions, the rights of third parties or that does not comply with the Terms of Use. This would be the
      case, for example, of a Company Account created on the basis of false information or invalid email addresses.
    </p>

    <p>
      Once created its Company Account, the Company, through the Manager, will be able to define all the Users likely to
      benefit from the Services and, if necessary, assign them Services.
    </p>

    <p>
      The definition of a User requires the provision of the surname, first name and e-mail address of the corresponding
      Employee. The Company undertakes to provide truthful information. Once defined, the User will be assigned a User
      Account.
    </p>

    <p>
      The assignment of Services to a given User will result in the provision to the User of all the defined Services,
      as well as the immediate invoicing of the Services concerned to the Company.
    </p>

    <p>
      In the event of the addition of certain Services, the Company will be immediately charged for additional services,
      for each of the Users concerned. Similarly, in the event of withdrawal of certain Services, the services in
      question will be immediately terminated for each of the Users concerned.
    </p>

    <h3>4. Access and use of the Application by Users</h3>

    <p>
      For each of the Users, the operation of the Application requires the prior subscription by the User or the Company
      – at their expense – of an Operator Subscription including (i) the possibility of making and receiving telephone
      calls and / or SMS and (ii) high-speed mobile Internet access.
    </p>

    <p>
      In the event of the subscription of an Operator Subscription by the User, it is agreed that the Company is
      responsible for subscribing and maintaining in force during the term of the Contract the User’s Operator
      Subscription.
    </p>

    <p>
      The subscription and maintenance in force of the Operator Subscription, essential for the use of the Application,
      are the sole responsibility of the User or the Company. The User must be equipped with a terminal allowing the use
      of the Application (Smartphone, PDA, tablet or equivalent) and having a recent version operating system and
      regularly updated. The User or the Company is solely responsible for the proper functioning of its terminals.
    </p>

    <p>
      The Company acknowledges that the actual use of the Application by each of the Users depends on the availability
      of communications and high-speed Internet services of the Operator Subscription of each of its Users. In addition,
      usage limits and geographic restrictions specific to the Application may vary. Thus, Users are advised to
      regularly consult the Website in order to know in more detail the restrictions of use and the recent changes made
      to the Application.
    </p>

    <p>
      Once the Application is installed on his terminal, the User will be able to access his User Account with a
      username and password that will have been communicated to him beforehand by ONOFF TELECOM by email. His password
      is personal and confidential and must not be shared with any third party in order to prevent any unauthorized use
      of the User Account. The Company undertakes to inform the User that he/she is solely responsible for the
      confidentiality of his/her password..
    </p>

    <p>
      ONOFF TELECOM reserves the right to suspend, refuse or cancel the subscription to the Contract or to suspend the
      performance of the Contract if one of the following conditions is not respected (i) the settlement at maturity of
      all debts contracted by the Company with ONOFF TELECOM under another contract, (ii) the provision of all the
      supporting documents requested by ONOFF TELECOM, (iii) the agreement given by the bank payment centers concerned
      and (iv) the exact declarations of the Company. In this case, the subscription request will be automatically
      refused or cancelled and the Company notified by email.
    </p>

    <p>
      During the contract, ONOFF TELECOM reserves the right to carry out and/or have carried out any additional
      investigation, in particular on the elements transmitted by the Company and, in case of reasonable doubt about the
      user of the Service or the documents that have been transmitted in support of the subscription, to refuse or
      cancel any subscription to the Contract. In this case, the subscription request will be automatically refused or
      cancelled and the Company notified by email.
    </p>

    <p>
      ONOFF TELECOM reserves the right to delete at any time a User Account that does not comply with the applicable
      legal provisions, the rights of third parties or that does not comply with the Terms of Use. This would be the
      case, for example, of a User Account created on the basis of a false surname or an invalid e-mail address.
    </p>

    <h3>5. Restrictions on use of the Application</h3>

    <p>
      The fee schedule as well as the content and limitations of each of the Services offered are detailed in the Fee
      Schedule.
    </p>

    <p>
      The Company acknowledges that the User must make normal use of the Application and associated Services and that
      ONOFF TELECOM may put in place measures to prevent any obvious misuse of the purpose of the Services.
    </p>

    <p>
      ONOFF TELECOM will evaluate the normal use of the Services in comparison with the uses of other Users. Consumption
      will be considered abnormal, in that it will be considered to constitute a diversion of the purpose of the
      Services, if it deviates substantially from the average consumption of Users.
    </p>

    <p>
      The Company is further informed that the following uses, although this list cannot be considered exhaustive, are
      considered abnormal:
    </p>

    <ul>
      <li>
        the issuance of more than fifteen (15) calls in less than three (3) minutes from a User Account,
      </li>

      <li>
        the issuance of calls of more than 2 hours from a User account,
      </li>

      <li>
        the use of the Application and / or the Services for the realization of a marketing campaign for commercial
        purposes, spamming, the provision of automated services,
      </li>

      <li>
        the resale or attempted resale of the Services to third parties,
      </li>

      <li>
        the use of Numbers from a given country without being able to justify a stable link involving a frequent and
        significant presence in that country.
      </li>
    </ul>

    <p>
      If ONOFF TELECOM detects an abnormal use of the Services, it will notify the Company or the User concerned of the
      abnormal nature of the use of the Services. ONOFF TELECOM may then immediately suspend the Services temporarily or
      permanently, without its liability being incurred under this suspension.
    </p>

    <p>
      The User must use the Application in compliance with applicable law and regulations.
    </p>

    <p>
      The User must in no case make unauthorized use of the Application or Services, that is to say contrary to the
      above. The Company is further informed that the following uses, although this list cannot be considered
      exhaustive, are considered unauthorized:
    </p>

    <ul>
      <li>
        harm or attempt to harm the Application, and in particular:
        <ul>
          <li>
            use a method designed to distort, alter, modify, disassemble, erase the Application, protocols and
            communications, such as, but not limited to, viruses, Trojan horses, a worm, logic bombs, etc.;
          </li>

          <li>
            alter or attempt to alter the Application, its functionality or availability;
          </li>

          <li>
            circumvent, disable or disrupt functions related to the security of the Application and its integrity (or
            that of any electronic equipment whatsoever), or functions that prevent or limit the use of the content of
            the Software;
          </li>
        </ul>
      </li>

      <li>
        upload and/or share inappropriate content (illegal, offensive to human dignity, sexual, defamatory, abusive,
        racist or any other content that may engage the civil or criminal liability of the person who publishes it);
      </li>

      <li>
        engage in activities that infringe on the privacy of others, including by impersonating another person;
      </li>

      <li>
        modify, intercept or monitor any communication not intended for it;
      </li>

      <li>
        harvest information from the Application that would allow personal identification including account names;
      </li>

      <li>
        use the Application in order to send, or help others to send, unwanted messages. All unsolicited messages and
        contact requests ("spam", "SPIT or Spam over Internet Telephony", "junk mail" or misleading and misleading
        content) and sent en masse, whether in the form of e-mails, calls, instant messages or other, are considered to
        be undesirable;
      </li>

      <li>
        use the Application for phishing, identity theft or misdirection;
      </li>

      <li>
        use the Application to embarrass, threaten, harass or invade the privacy of a third party, or expose a third
        party to offensive, indecent or dangerous content;
      </li>

      <li>
        Infringe the intellectual property rights of ONOFF TELECOM, another User, or a third party, and in particular:
        <ul>
          <li>
            use in any way whatsoever any content subject to proprietary rights not belonging to the Company or the
            User, unless the latter holds a license or the express permission of the owner of the rights;
          </li>

          <li>
            resell or market the Application, while it is intended for the User's personal use.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      In addition, the User and the Company are expressly reminded that, in accordance with regulations establishing the
      national numbering plan and its management rules, any Number may not be used as a "secondary number" for the
      provision of telephone or messaging services by SMS or MMS, whose subscriber can be reached without any
      restriction by any user of a telephone service to the public or of SMS or MMS, only as long as these services are
      only usable from mobile access.
    </p>

    <p>
      It is also recalled that this "secondary" number may be provided by an operator different from the one that
      provides mobile access to the User.
    </p>

    <p>
      Finally, it is recalled that a Number can only be assigned to a User habitually or temporarily residing, or
      justifying stable links involving a frequent and significant presence, in the territory corresponding to this
      Number. The Company is committed to respecting these conditions of territoriality and undertakes to cancel the
      assignment of a Number to any Employee who no longer meets the aforementioned conditions of territoriality.
    </p>

    <p>
      If ONOFF TELECOM detects an unauthorized use of the Application or Services, it will notify the Company or the
      User. ONOFF TELECOM may then immediately suspend the Services temporarily or permanently or terminate the
      Contract,without its liability being incurred for such suspension or termination.
    </p>

    <h3>6. Update and maintenance</h3>

    <p>
      ONOFF TELECOM may at any time make updates to the Application and the Management Console.
    </p>

    <p>
      These updates may be intended to maintain software compatibility, offer new features or versions of the
      Application or management console, provide security updates or bug fixes, etc. ONOFF TELECOM reserves the right to
      check the versions of the Application and download configuration changes and updates to the Application. ONOFF
      TELECOM does not guarantee that the updated Application is always compatible with older versions of the
      Application. ONOFF TELECOM also does not guarantee to continue to support a version of the operating system or
      device for which the User has a License.
    </p>

    <p>
      The Company acknowledges that Users must always use an up-to-date version of the Application.
    </p>

    <p>
      ONOFF TELECOM may modify the Application or the Management Console at any time and for this purpose to carry out
      maintenance or upgrade operations of the Application or the Management Console, or the underlying infrastructure
      allowing the use of the Application or the Management Console. ONOFF TELECOM may in this context temporarily
      suspend or limit the use of all or part of the Application or Services. Except in cases of emergency, temporary
      interruptions will, as far as possible, be notified on the Website before they occur. ONOFF TELECOM will in no
      case be liable for damages to the Company in the event of suspension or limitation o
    </p>

    <h3>7. Description of the Services</h3>

    <p>
      Once the registration steps have been completed by his Company, the User may obtain from ONOFF TELECOM according
      to the instructions given to ONOFF TELECOM by his Company and the Services assigned to him through the Management
      Console, one or more Numbers and/or one or more call packages ("Packages"), and benefit from the associated
      functionalities. The assignment of a Number gives access to all linked Services. The Numbers make it possible to
      make and receive telephone calls and/ or, where appropriate, SMS and/or MMS to and from any person with a
      telephone number subject to the limitations set out in the following paragraph concerning calls to certain classes
      of numbers.
    </p>

    <p>
      As the Services are in no way a substitute for the primary mobile telephony service provided by the Operator
      Subscription, the Company acknowledges and accepts that the Numbers do not allow calls to be made to all numbers.
      Consequently, calls that cannot be made from the Application (i.e. in particular but not exhaustively calls to
      special numbers and emergency numbers) must be made directly from the number associated with the User's Operator
      Subscription.
    </p>

    <p>
      Each Number can be activated or temporarily deactivated via the application interface. The User may, in the same
      way, activate and deactivate the calls and SMS of each Number.
    </p>

    <h3>8. Terms of Use of the Services</h3>

    <p>
      Access to and use of the Services by the User requires (i) the use of the User's Operator Subscription, and in
      particular the SIM card associated with the Operator Subscription, the corresponding telephone credit and access
      to the Internet network and (ii) a terminal allowing the use of the Services (Smartphone, PDA, tablet or
      equivalent) and having an operating system of recent version and updated periodically.
    </p>

    <p>
      ONOFF TELECOM recalls that the availability of the Services depends on the availability of communications and
      high-speed Internet services of the Operator Subscription of each User.
    </p>

    <p>
      By being assigned a Number, the Company and the User agree to the following:
    </p>

    <ul>
      <li>
        neither the Company nor the User has any ownership right over the Number(s), nor any right to the permanent
        storage or maintenance of the Number(s);
      </li>

      <li>
        the User must use the Application in compliance with applicable law and regulations and the Terms of Use;
      </li>

      <li>
        the User must comply at all times with the applicable numbering rules and the special requirements for the
        assignment of Numbers as well as with any instructions that ONOFF TELECOM may send him with regard to the
        Number(s);
      </li>

      <li>
        the Company has a revocable right to use the Number, excluding in particular its assignment, subletting,
        transfer, portability or more generally any act of disposition or security.
      </li>
    </ul>

    <p>
      ONOFF TELECOM reserves the right to modify the conditions of access to the Numbers and provision of the Services,
      in particular in the event of a change in the applicable legal and regulatory provisions or to take into account
      any administrative or judicial decision that would be applicable to the terms of provision of the Numbers and
      associated Services.
    </p>

    <h3>9. Quality of Service</h3>

    <p>
      ONOFF TELECOM applies the security measures necessary for the prevention of incidents and implements the means
      allowing it to react to an incident to limit the unavailability of the Services and the impact on users' data.
    </p>

    <p>
      ONOFF TELECOM undertakes to achieve, in the covered area, the following levels of service:
    </p>

    <ul>
      <li>
        availability of the Services within a maximum period of six (6) hours from the creation of the User Account and
        the assignment of the Services to the User,
      </li>

      <li>
        a monthly availability of the Services at least equal to 90%, the availability of the Service being understood
        to mean the material possibility of transmitting or receiving a communication,
      </li>

      <li>
        a reinstatement of the Services within a maximum period of forty-eight (48) hours from the notification of the
        interruption of the Services to the customer support service (the "Support") of ONOFF TELECOM accessible through
        the Website. Interruption of the Service means the material impossibility of transmitting or receiving a
        communication.
      </li>
    </ul>

    <p>
      The service levels apply when the following conditions are cumulatively met: (i) the User is located in an area
      usually covered by the Service and its Operator Subscription and (ii) has a terminal compatible with the
      Application, lit and in perfect working order.
    </p>

    <p>
      In the event that ONOFF TELECOM does not reach one of the aforementioned levels of service, the Company will have
      a period of one (1) month to send a request for compensation specifying the User, the incident concerned and its
      duration to the Support. ONOFF TELECOM undertakes to process compensation requests (i) within five (5) working
      days of their receipt with regard to the compliance of the Company's request with the conditions detailed in this
      article and (ii) within one month of their receipt for their full examination. If the request is justified, ONOFF
      TELECOM will award the affected Company compensation equivalent to one (1) month of subscription to the assigned
      Service.
    </p>

    <p>
      However, such claims for compensation shall not be admissible in the following cases:
    </p>

    <ul>
      <li>
        in the absence of a request for compensation made by the Company within one (1) month of non-compliance with the
        aforementioned levels of service,
      </li>

      <li>
        in the event of fault on the part of the Company and/or the User concerned or misuse by the Company and/or the
        User and/or its correspondents of the Service,
      </li>

      <li>
        in case of incorrect configuration of the Application,
      </li>

      <li>
        in case of non-compliant use of the Application,
      </li>

      <li>
        in the event of the transmission of radio signals affected by the constraints or limits of the technical
        standards imposed on the operator with whom the Operator Subscription is subscribed or on the operator(s)
        partner(s) of ONOFF TELECOM by the competent regulatory authorities or regulatory groups,
      </li>

      <li>
        in the event of a maintenance operation by ONOFF TELECOM or the operator(s) partner(s) of ONOFF TELECOM,
      </li>

      <li>
        in the event of a disturbance or interruption not directly attributable to ONOFF TELECOM,
      </li>

      <li>
        in the event of disruption and/or total or partial unavailability, and/or interruption of all or part of the
        services offered on the networks of the operator with whom the Operator Subscription is subscribed,
      </li>

      <li>
        in case of force majeure.
      </li>
    </ul>

    <h3>10. Hosting of communications content</h3>

    <p>
      ONOFF TELECOM undertakes to ensure the security, confidentiality and integrity of communications made by the
      Application in accordance with the applicable legal and regulatory obligations.
    </p>

    <p>
      Nevertheless, the contents stored, used, transmitted and received by the User through the Application are under
      his sole responsibility. It is the User's responsibility to ensure that he does not violate the legal and
      regulatory provisions in force, in particular relating to the intellectual property rights attached to certain
      content.
    </p>

    <p>
      The Company and its Users are solely responsible for the use they make or intend to make of the functionalities of
      the Application and user content. ONOFF TELECOM does not ensure the control or promotion of User Content. ONOFF
      TELECOM assumes no responsibility for any such User Content, including, without limitation, media shared through
      the use of the Application. In addition, the Company acknowledges and accepts that ONOFF TELECOM does not exercise
      any a priori control over the User Content and that it is not bound by a general obligation to monitor such
      content. Similarly, although it has access to User content, ONOFF TELECOM has no control over the sites and
      external sources to which hypertext links accessible via the Application redirect. ONOFF TELECOM is authorized to
      communicate any User content at the request of any administrative or judicial authority.
    </p>

    <p>
      Any User or Company has the possibility to notify ONOFF TELECOM of any User Content that is illegal or infringes
      the User's rights in order to request its withdrawal. The Company will, in this case, be solely responsible for
      the complaint it has filed with ONOFF TELECOM. The Company acknowledges and agrees that any complaint filed by it
      may be used in any legal proceedings. The Company also acknowledges and agrees that any inaccurate, false or
      misleading information may engage its civil and criminal liability.
    </p>

    <p>
      Any complaint or notification falsely accusing User Content of infringing the rights of the notifying Company or
      its Users or the rights of third parties, by having knowledge of the inaccuracy of this statement, makes the
      notifying Company liable to prosecution. The notifying Company could thus be ordered to pay damages, costs and/or
      legal costs incurred by it, as well as by any rightholder who has suffered damage as a result of the trust placed
      by ONOFF TELECOM in this incorrect declaration. The notifying Company, in this case, may also, if necessary, be
      the subject of criminal proceedings.
    </p>

    <p>
      The Company declares and warrants to have informed each of its Employees of the following:
    </p>

    <ul>
      <li>
        a User shall only post or upload User Content if he or she has the necessary consents, authorizations and
        licenses.
      </li>

      <li>
        a User may only use, publish or include any personal element to a third party within the User Content if he has
        the express permission, consent or permission of that person to that effect.
      </li>
    </ul>

    <p>
      By publishing or downloading User Content, the Company and each of the Users agree and agree to grant ONOFF
      TELECOM a non-exclusive, irrevocable, royalty-free, sublicensable and transferable worldwide license to all rights
      of use, modification, representation, translation, exploitation, editing, inclusion, recording, adaptation,
      reproduction and others. This license is granted for the maximum period of protection by intellectual property
      rights of the User Content published or downloaded by the User.
    </p>

    <p>
      The Company and each of the Users guarantee ONOFF TELECOM against any claim brought by third parties or other
      Users on the grounds that the User Content would constitute a violation of the intellectual or industrial property
      rights claimed by third parties or other Users, ONOFF TELECOM cannot be sought or worried about this.
    </p>

    <p>
      Under applicable law, ONOFF TELECOM reserves the right, although it has no obligation to monitor User Content, to
      access such User Content at any time and without prior notification, in order to verify compliance with the Terms
      of Use, to verify any violation or alleged violation of the rights of third parties, to verify the liability of
      Users following complaints from individuals and/or requests from authorities, and to take the necessary measures,
      including the removal of User Content, temporary suspension or termination of the Services.
    </p>

    <h3>11. Intellectual property</h3>

    <p>
      The Application contains proprietary and confidential information protected by intellectual property laws and
      treaties. ONOFF TELECOM retains the exclusive ownership of the Application and the Company and/or the User shall
      refrain, directly, indirectly or through third parties, from infringing, jeopardizing or limiting, by any measure
      whatsoever, the intellectual property rights held by ONOFF TELECOM on the Application. Similarly, any
      reproduction, representation, adaptation, modification, translation, transformation, dissemination, integration,
      in other material, commercial exploitation or not and / or reuse in any way whatsoever of all or part of the
      elements composing the Application is strictly prohibited. ONOFF TELECOM, the trademarks and associated logos are
      trademarks owned by ONOFF TELECOM.
    </p>

    <p>
      The User has, subject to acceptance and compliance with the Terms of Use, a limited, non-exclusive,
      non-transferable, non-sellable right, free of charge, to download and install the Application on a terminal, such
      as Smartphone or tablet, and to use the Application on a personal basis through his User Account. This right is
      conferred on the User for the entire duration of this Contract and throughout the territory in which ONOFF TELECOM
      markets the Services.
    </p>

    <p>
      The User shall refrain from:
    </p>

    <ul>
      <li>
        license, sell, assign, rent, export, import, distribute or transfer or grant rights to any third party in the
        Application;
      </li>

      <li>
        in any way, modify, copy, decompile, reverse engineer, disassemble all or part of the elements composing the
        Application, or attempt to derive the source code, or create derivative works from all or part of the
        Application or its updates, except where otherwise provided by applicable law;
      </li>

      <li>
        directly or indirectly, create a derivative work, search for the source code or protocols of the Software of the
        Application (except to the extent permitted by law);
      </li>

      <li>
        remove, hide or modify trademark or other proprietary notices included in the Application.
      </li>
    </ul>

    <p>
      Any action set out above will be considered a violation of the rights of ONOFF TELECOM. Any non-compliant use that
      the Application may lead to legal proceedings.
    </p>

    <p>
      This section will govern any update provided by ONOFF TELECOM that replaces and/or adds to the Application, unless
      it is accompanied by a separate license.
    </p>

    <h3>12. Personal data</h3>

    <p>
      The collection and use of the personal data of the User and the Manager are defined in the Personal Data
      Protection Policy.
    </p>

    <p>
      Due to the purpose of the Service and the associated confidentiality, registration in the universal directory will
      not be carried out by ONOFF TELECOM. However, the User may request his registration in the universal directory by
      express request addressed to onoff telecom support. In this case, the User will communicate, under his
      responsibility, his contact details (telephone number and surname, first name or initials subject to homonymy,
      company name, postal address complete or not, e-mail address, profession or activity) so that they appear free of
      charge in the lists of ONOFF TELECOM intended for publishers of directories and information services.
    </p>

    <h3>13. Financial conditions</h3>

    <p>
      All rates applicable to the Services and in particular applicable to Numbers, Call Packages and ONOFF Credits are
      mentioned, regularly updated and available via the Management Console and at the address:
      { ' ' }
      <a href="onoffbusiness.com/pricing">
        onoffbusiness.com/pricing
      </a>
      . The Services provided to Users by ONOFF TELECOM are invoiced to the Company in
      accordance with the price in force on the day of subscription of the Service concerned.
    </p>

    <p>
      Any general reduction in tariffs may be carried out by ONOFF TELECOM without any particular delay. When a
      particular discount has been granted when subscribing to a Service, ONOFF TELECOM reserves the right to no longer
      apply the initial tariff discount on the new pricing.
    </p>

    <p>
      In the event of an upward change in the applicable rates, ONOFF TELECOM will inform the Company at least one (1)
      month before the entry into force of the amended tariff. The Company may then terminate the Service concerned
      through the Management Console and under the conditions defined below in Article 15.
    </p>

    <p>
      The subscription to each Number is subject to the payment of a fixed price variable according to the category and
      the period of subscription of said Number.
    </p>

    <p>
      The subscription to the Services will automatically renew at the end of each period, for all Users, unless the
      Company Account is terminated. The renewal of the subscription will entail invoicing and payment of the
      corresponding amount.
    </p>

    <p>
      The Company will pay monthly and in advance all the Services subscribed. To this end, the Company must ensure that
      its Company Account references one or more valid means of payment among those accepted in payment by ONOFF
      TELECOM.
    </p>

    <p>
      Any purchase or renewal of Services will be the subject of an invoice in electronic format made available to the
      Company through the Management Console.
    </p>

    <p>
      Failure by the Company to pay an invoice on the due date will automatically and without formality entail:
    </p>

    <ul>
      <li>
        in accordance with the provisions of Article L. 441-6 of the French Commercial Code, the invoicing of late
        payment penalties, the amount of which will be calculated on the amount of all taxes included in the invoice
        due, it being specified that the rate of these late payment penalties will be (i) due by the Company solely
        because of the due date at the contractual term, (ii) due by the Company notwithstanding any additional damages
        to which ONOFF TELECOM could claim as a result of the non-payment in question and (iii) equal to the interest
        rate applied by the European Central Bank to its most recent refinancing operation increased by 10 percentage
        points and this, under the conditions defined in Article L. 441-6 of the French Commercial Code, that interest
        rate may not, however, be less than three times the legal interest rate,
      </li>

      <li>
        the payment of a lump sum recovery indemnity, notwithstanding the absence of a recovery procedure, set at an
        amount of forty (40.00) euros in application of the provisions of Articles L. 441-6 and D. 441-5 of the French
        Commercial Code.
      </li>

      <li>
        Under Article L.441-6 of the French Commercial Code, ONOFF TELECOM may claim any additional compensation, when
        the costs it has incurred are greater than the amount of the lump sum compensation (in particular the costs of a
        lawyer or a debt collection company).
      </li>

      <li>
        the cancellation of all discounts, rebates, rebates and other advantages granted by ONOFF TELECOM for the
        benefit of the Company and/or users.
      </li>
    </ul>

    <p>
      In case of disagreement on the amount of a payment, it is made on the provisional basis of the sums admitted and
      established by ONOFF TELECOM. The sums that will be due to ONOFF TELECOM under the Contract will not be
      compensable in due respect to any other sums or claims that ONOFF TELECOM would be liable to the Company.
    </p>

    <p>
      The Company shall have ninety (90) days from the date of invoice to contest the amounts invoiced to it with all
      the necessary justifications. After this period, the Company will no longer be able to request any refund.
    </p>

    <p>
      ONOFF TELECOM reserves the right to refuse any refund request if it considers (i) that the Company's request is
      unfounded or (ii) that the Company has not complied with the Terms of Use.
    </p>

    <p>
      Any reduction in the disputed invoice will result in the issuance of a note deducted from the next invoice.
    </p>

    <p>
      Pursuant to Article L. 34-2 of the French Postal and Electronic Communications Code, the limitation period is
      acquired for all claims for restitution of the price of electronic communications services presented by the
      Company after a period of one year from the day of payment.
    </p>

    <h3>14. Changes to the Terms of Use</h3>

    <p>
      ONOFF TELECOM informs the Company of any modification of the Terms of Use prior to their entry into force. If the
      Company refuses that this modification applies to its current Services, it must terminate its Contract within one
      month of the information of the modification, under the conditions defined below in Article 15. In the absence of
      termination within this period, the amended Terms of Use shall automatically apply at the end of this period.
    </p>

    <h3>15. Effective date, duration and termination</h3>

    <p>
      The Contract takes effect on the date of creation of the Business Account and will remain in force until the
      cumulative fulfilment of the following two conditions: (i) the termination by one of the Parties of all the
      Services provided to all Users and (ii) the deletion by the Company of its Company Account.
    </p>

    <p>
      On the effective date of termination of the Contract by the Company, it will have the following consequences:
    </p>

    <ul>
      <li>
        all licenses and rights to use the Application will terminate;
      </li>

      <li>
        each User must stop using the Application;
      </li>

      <li>
        each User shall remove the Application from all hard drives, networks and storage media and destroy all copies
        in his possession, or under his control;
      </li>

      <li>
        ONOFF TELECOM may block access to User Accounts and/or the Application;
      </li>

      <li>
        ONOFF TELECOM may delete the data associated with User Accounts.
      </li>
    </ul>

    <p>
      Termination of a particular Service will have the same consequences as those set forth above, which will be
      limited to the terminated Service only.
    </p>

    <p>
      Each Party may also terminate the Contract for fault of the other Party eight (8) days after the sending of a
      formal notice remained in vain, this resulting from the sole breach of the offending party. It is agreed that the
      following cases may result in the automatic termination of the Contract by ONOFF TELECOM:
    </p>

    <ul>
      <li>
        if the Company has violated the Terms of Use;
      </li>

      <li>
        if the information provided by the Company in its Company Account is inaccurate;
      </li>

      <li>
        if ONOFF TELECOM suspects an illegal or fraudulent use of the Application or contrary to the rights of third
        parties;
      </li>

      <li>
        if ONOFF TELECOM suspects that the Company is attempting to take unfair advantage of any of ONOFF TELECOM's
        policies, or to misuse them.
      </li>
    </ul>

    <p>
      ONOFF TELECOM may terminate its relationship with the Company at any time subject to compliance with a minimum
      notice period of one (1) month before the end of the commitment period.
    </p>

    <p>
      If ONOFF TELECOM decides to no longer offer a particular Service, it may terminate the said Service without
      penalty, subject to compliance with a minimum notice of one month.
    </p>

    <p>
      Any termination of ONOFF TELECOM will be notified to the Company by e-mail.
    </p>

    <p>
      The Company understands and accepts that the fact that a User does not use a Number under the conditions defined
      in the General Conditions of Use entails the automatic and automatic termination of the Number as well as the data
      relating to the use of the Number (for example: SMS, call log, voice messages, etc.).
    </p>

    <h3>16. Suspension</h3>

    <p>
      Without prejudice to the possibility of terminating the Contract, ONOFF TELECOM may suspend the Services or access
      to the Application of one of the Users on simple written information of the User concerned in the following cases:
    </p>

    <ul>
      <li>
        if the User has violated the Terms of Use;
      </li>

      <li>
        if the information provided for the User in his User Account is inaccurate, incomplete or if the User's mobile
        number provided by the operator with whom the Operator Subscription was subscribed is no longer valid;
      </li>

      <li>
        if the conditions of territoriality required for the assignment of the number to the User are no longer met;
      </li>

      <li>
        if ONOFF TELECOM suspects illegal or fraudulent or abnormal use of the Application or contrary to the rights of
        third parties;
      </li>

      <li>
        if ONOFF TELECOM suspects that the User is trying to take unfair advantage of ONOFF TELECOM's refund policy or
        any other policies, or to misuse them;
      </li>

      <li>
        if ONOFF TELECOM suspects that the User Account is the subject of fraudulent use by a third party.
      </li>
    </ul>

    <p>
      ONOFF TELECOM is also likely to interrupt the use of the Application under certain conditions including, but not
      limited to, the impossibility of providing the Service, problems of external origin that make the implementation
      of the Service difficult or careless, technological progress, the need to modify the Application or the Services.
    </p>

    <h3>17. Responsibility</h3>

    <p>
      ONOFF TELECOM undertakes to provide all the necessary competence and care for the provision of the Services and to
      implement all the necessary provisions in order to ensure the Services permanently and continuously. As such,
      ONOFF TELECOM has only an obligation of means.
    </p>

    <p>
      With regard to the relevant and applicable legal provisions, the Application is provided "as is" and will be used
      at the User's own risk, without any guarantee. In this sense, the Company and/or the User are solely responsible
      for the preservation, security and integrity of data, hardware and software, as well as the confidentiality of its
      passwords and User Account identifiers and others in the context of the use of the Services.
    </p>

    <p>
      ONOFF TELECOM assumes no responsibility to any third party or to the User and the Company regarding the contents,
      the latter being solely responsible for the User Content submitted, created, displayed or published through the
      Application.
    </p>

    <p>
      ONOFF TELECOM makes its best efforts to ensure the confidentiality of the information and content published
      through the Application.
    </p>

    <p>
      ONOFF TELECOM does not guarantee that the Application will always be available, accessible, uninterrupted,
      reliable, and error-free. In addition, no warranty is offered regarding internet connections or transmissions, or
      the quality of calls made through the Application.
    </p>

    <p>
      Should a third party file a complaint against ONOFF TELECOM in connection with, or as a result of (i) any breach
      by the User or the Company of the Terms of Use, (ii) any breach by the User or the Company of any applicable law
      or regulation, (iii) any violation by the User or the Company of the rights of third parties (including
      intellectual property rights), (iv) its User Content or (v) its complaint regarding any User Content, the Company
      and the User will jointly and severally guarantee ONOFF TELECOM against any damages, liabilities, losses,
      convictions, costs and expenses (including legal fees and other reasonable costs) that may result therefrom.
    </p>

    <p>
      Subject to the foregoing and in accordance with applicable law, ONOFF TELECOM shall not be liable to the Company
      or the User, whether or not ONOFF TELECOM has been informed of the possibility of such damages or losses, for:
    </p>

    <ul>
      <li>
        indirect damage and in particular any damage, loss, or corruption of data;
      </li>

      <li>
        damage resulting from the acts of third parties or cases of force majeure;
      </li>

      <li>
        any claim, damage or loss arising out of or related to:
        <ul>
          <li>
            the User's inability to use all or part of the Application or its functionality;
          </li>

          <li>
            the User's inability to make the necessary arrangements to contact the emergency services;
          </li>

          <li>
            the User's inability to provide accurate information about the physical location to an emergency department;
          </li>

          <li>
            the conduct of third-party emergency service agents and call centers with which the User is connected.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      As the Services are in no way a substitute for the primary mobile telephony service provided by the operator with
      whom an Operator Subscription has been subscribed, the Company acknowledges and accepts that the Numbers do not
      allow calls to be made to all classes of numbers. Consequently, calls that cannot be made from the Application
      (i.e. in particular calls to special numbers, international numbers and emergency numbers) must be made directly
      from the User's telephone. ONOFF TELECOM reserves the right to allow Users to make calls to additional number
      classes after the launch of the Services.
    </p>

    <p>
      In addition to the foregoing, ONOFF TELECOM cannot be held liable to the Company or the User, whether or not ONOFF
      TELECOM has been informed of the possibility of such damages or losses, for:
    </p>

    <ul>
      <li>
        any indirect damage, and in particular, any loss of income, contracts, real or expected profits, luck, brand
        image or reputation, customers or turnover;
      </li>

      <li>
        any damage or corruption of data;
      </li>

      <li>
        any claim, damage or loss arising out of or related to:
      </li>

      <li>
        the User's inability to use all or part of the Application or its functionality;
      </li>

      <li>
        the User's inability to make the necessary arrangements to contact the emergency services
      </li>

      <li>
        the User's inability to provide accurate information about the physical location to an emergency department;
      </li>

      <li>
        the conduct of third-party emergency service agents and call centers with which the User is connected.
      </li>

      <li>
        any damage in excess of an amount, all counts of damages combined, corresponding to the total amount paid by the
        User for the Services during the twelve (12) month period immediately preceding the date of occurrence of the
        event at the origin of the request in question.
      </li>
    </ul>

    <p>
      To the extent that the Company demonstrates that it has suffered direct damage, ONOFF TELECOM's liability will be
      expressly limited to one hundred percent (100%) of the sums actually received by ONOFF TELECOM under the Contract
      during the last six (6) months preceding the occurrence of the said damage.
    </p>

    <p>
      Actions for liability against ONOFF TELECOM are time-barred by two years from the date of the event giving rise to
      the damage.
    </p>

    <h3>18. Force Majeure</h3>

    <p>
      ONOFF TELECOM cannot be held liable in the event that the non-performance results from a case of force majeure.
    </p>

    <p>
      Are considered as cases of force majeure, as long as they are not the work of ONOFF TELECOM and are beyond its
      control, exceptional bad weather, natural disasters, fires and floods, lightning, electronic power surges,
      attacks, internal or external strikes, internal or external failures or failures such as failures related to the
      telephone network, to the network of the service provider(s) of ONOFF TELECOM, and all those emanating from the
      mobile telephone operator with whom an Operator Subscription has been subscribed, and in general any irresistible
      and unforeseeable event that does not allow the proper functioning of the Application.
    </p>

    <h3>19. Execution in good faith</h3>

    <p>
      Each of the parties undertakes to perform the Contract that binds them in a spirit of loyalty and transparency. As
      such, each of the parties:
    </p>

    <ul>
      <li>
        refrains from any conduct likely to harm, directly or indirectly, the reputation, honour and/or image of the
        other party;
      </li>

      <li>
        will promptly inform the other party of any change concerning him and/or any difficulty he encounters that may
        have an impact on the execution of the Contract and this, in order to try to resolve it;
      </li>

      <li>
        will make its best efforts to take all reasonable steps to moderate or limit the harm that may be caused to it
        as a result of the non-performance of the Contract.
      </li>
    </ul>

    <h3>20. Miscellaneous stipulations</h3>

    <p>
      The Contract may be the subject of a total or partial transfer, for consideration or free of charge as part of a
      universal transfer of assets, merger, division, contribution to a company, partial contribution of assets, or any
      other restructuring operation, by ONOFF TELECOM, which is already authorized by the Company. Pursuant to the
      provisions of Article 1216 of the French Civil Code, the assignment of the Contract will have its effects with
      respect to the Company when the contract concluded between ONOFF TELECOM and the new beneficiary is notified to it
      or when it takes note of it; in this case, ONOFF TELECOM will no longer be jointly and severally liable for the
      performance of the Contract, which is already accepted by the Company.
    </p>

    <p>
      In the event that any of the stipulations of the Terms of Use are declared null or void in any way and for any
      reason whatsoever, the parties undertake to consult each other to remedy the cause of nullity found, so that,
      unless impossible, the Contrat continues its effects without interruption. The invalidity of a clause of the Terms
      of Use will not result in the nullity of the other clauses or of all the Terms of Use.
    </p>

    <p>
      No silence kept by ONOFF TELECOM to strictly enforce any of its rights under the Terms of Use shall be considered
      as a waiver of any provision here and result in the forfeiture of this right. In addition, no waiver by ONOFF
      TELECOM to avail itself of any of the stipulations of the Conditions of Use or right resulting from the Conditions
      of Use will have no effect if it is not the subject of a writing signed by ONOFF TELECOM. In addition, the waiver
      by ONOFF TELECOM to avail itself of any right resulting from the Conditions of Use or a stipulation of the
      Conditions of Use in specified circumstances will not constitute a waiver to avail itself subsequently of said
      right or the said stipulation.
    </p>

    <p>
      ONOFF TELECOM grants the Company the right to use the name and trademark as well as the functional and synthetic
      description of the services contracted as part of the Company's communication actions: press releases, marketing
      brochures, registration on the websites, subject to validation by ONOFF TELECOM. ONOFF TELECOM reserves the right
      to quote the Company by reference, in particular to include the name of the Company, its logo, in any commercial
      and /or technical support (commercial proposal, performance report ...), in any communication medium and in any
      place whatsoever (POS, customer case, website, social networks, trade shows ...)
    </p>

    <p>
      Pursuant to the provisions of article 1366 of the French Civil Code, the computerized files, data, messages and
      registers kept in ONOFF TELECOM's computer systems will be accepted as proof of communications and agreements
      between ONOFF TELECOM and the Company, insofar as the party from which they emanate can be identified and that
      they are established and kept in conditions likely to guarantee their integrity.
    </p>

    <p>
      The parties to the Contract have agreed to bear the risk of any unforeseeable change in circumstances which would
      render the performance of the obligations of each of the parties under the Contract too onerous. Each of the
      parties expressly acknowledges that the provisions of Article 1195 of the French Civil Code are not applicable to
      the Contract and that it may not submit any request of any kind and in any form whatsoever, and in particular
      having the purpose of renegotiating and/or asking the Courts to revise or terminate, pursuant to the provisions of
      Article 1195 of the French Civil Code, the Contract. However, if a change in circumstances unforeseeable at the
      time of the conclusion or renewal of the Contract makes the performance of the Contract excessively onerous and
      harmful for a party who had not agreed to assume the risk, the latter may notify the other party of its wish to
      request a renegotiation of the Contract, this notification must include a statement of the reasons and a copy of
      the supporting documents relating to its request. Each of the parties will have to continue to perform its
      obligations during the renegotiation, it being specified that the party to whom the request for renegotiation is
      addressed will not be required to give a favourable response to this request.
    </p>

    <h3>21. Applicable law – dispute resolution</h3>

    <p>
      The Terms of Use and the terms of provision of the Services are subject to French law.
    </p>

    <p>
      ONOFF TELECOM provides the Company and Users with a support service accessible via its Website. Any claim must be
      made through this service prior to any legal action. ONOFF TELECOM undertakes to provide a response to any dispute
      or request for reimbursement within thirty (30) working days from the date of receipt thereof.
    </p>

    <p>
      In the event of a dispute arising from the interpretation or execution of the Contract, the parties will endeavour
      to seek an amicable solution. Any Company established in France is informed that it can refer any dispute with
      ONOFF TELECOM to the Mediator of Electronic Communications free of charge on its website:
      { ' ' }
      <a href="www.mediateur-telecom.fr">
        www.mediateur-telecom.fr
      </a>
      .
    </p>

    <p>
      In the absence of an amicable solution between the parties within a period of thirty (30) calendar days from the
      receipt by the receiving party of the request for conciliation, any dispute concerning the formation, validity,
      interpretation, execution or termination of the Contract will fall exclusively under the jurisdiction of the
      courts
      within the jurisdiction of the Court of Appeal of PARIS (FRANCE), notwithstanding several defendants, forced
      intervention, in particular appeal in warranty, this attribution of jurisdiction also applying in matters of
      summary
      proceedings, urgent or protective procedures or application.
    </p>
  </>
);

export default EnglishText;
