/* eslint-disable react/jsx-no-literals,react/no-unescaped-entities,max-len */
import React from 'react';

const FrenchText: React.FC = () => (
  <>
    <h2>CONDITIONS GÉNÉRALES D’UTILISATION D’ONOFF Business</h2>

    <h3>1. Définitions</h3>

    <p>
      Les termes suivants figurant dans les Conditions d’Utilisation ont, sauf indication contraire, la signification
      suivante :
    </p>

    <p>
      <strong>Abonnement Opérateur :</strong>
      { ' ' }
      désigne le contrat de services de téléphonie mobile et d’Internet haut
      débit
      mobile souscrit par l’Utilisateur ou par l’Entreprise auprès d’un opérateur tiers préalablement à l’utilisation
      des
      Services et indépendamment d’ONOFF TELECOM.
    </p>

    <p>
      <strong>Application :</strong>
      { ' ' }
      désigne toute application (dont le logiciel dénommé ONOFF Business), développée et
      exploitée par ONOFF TELECOM, téléchargeable sur les plateformes de distribution d’applications et permettant à
      l'Utilisateur de bénéficier des Services. Le terme Application désigne également la solution permettant
      l’utilisation de Numéros à travers des applications appartenant à des tiers (Microsoft Teams ou Zoom).
    </p>

    <p>
      <strong>Collaborateur :</strong>
      { ' ' }
      désigne l’un des collaborateurs (salarié ou mandataire social) de l’Entreprise.
    </p>

    <p>
      <strong>Compte Entreprise :</strong>
      { ' ' }
      désigne le compte créé par l'Entreprise afin de définir ses Collaborateurs
      et,
      pour chacun d'eux, le type de Services dont il devra bénéficier.
    </p>

    <p>
      <strong>Compte Utilisateur :</strong>
      { ' ' }
      désigne le compte du Collaborateur, créé par le Manager, et permettant au
      Collaborateur d’utiliser l’Application.
    </p>

    <p>
      <strong>Conditions d’Utilisation :</strong>
      { ' ' }
      désigne les présentes conditions générales d’utilisation régissant,
      avec
      la Politique de Protection des Données Personnelles et la Grille Tarifaire, les relations contractuelles relatives
      à
      la fourniture des Services par ONOFF TELECOM à l’Entreprise.
    </p>

    <p>
      <strong>Console de Gestion :</strong>
      { ' ' }
      désigne les outils mis à disposition de l'Entreprise, via le site web
      <a href="onoffbusiness.com">
        onoffbusiness.com
      </a>
      , et permettant à un Manager de (1) créer le Compte Entreprise, (2) lister l'ensemble des
      Collaborateurs susceptibles de bénéficier des Services, (3) leur donner un accès effectif aux Services concernés
      et
      (4) gérer ultérieurement l'accès de ces Utilisateurs aux Services.
    </p>

    <p>
      <strong>Contenu Utilisateur :</strong>
      { ' ' }
      désigne tout contenu publié par l’Utilisateur via l’Application.
    </p>

    <p>
      <strong>Contrat :</strong>
      { ' ' }
      désigne l’ensemble contractuel composé des Conditions d’Utilisation, de l’annexe
      tarifaire ainsi que la Politique de protection des données personnelles intervenant entre ONOFF TELECOM et
      l'Entreprise.
    </p>

    <p>
      <strong>Crédits ONOFF :</strong>
      { ' ' }
      désigne les crédits permettant d’acheter des Services, et notamment émettre des
      appels téléphoniques et/ou envoyer des SMS.
    </p>

    <p>
      <strong>Entreprise :</strong>
      { ' ' }
      désigne toute personne morale ou toute personne physique agissant en qualité de
      professionnel, immatriculée au registre du commerce et des sociétés ou au répertoire des métiers, ainsi que toute
      collectivité publique ou association, susceptible d'ouvrir un Compte Entreprise ONOFF Business afin de permettre à
      ses Collaborateurs d’utiliser l’Application.
    </p>

    <p>
      <strong>Grille Tarifaire :</strong>
      { ' ' }
      désigne la grille tarifaire applicable aux Services et disponible à l’adresse
      <a href="https://onoffbusiness.com/tarifs">
        https://onoffbusiness.com/tarifs
      </a>
      .
    </p>

    <p>
      <strong>Manager :</strong>
      { ' ' }
      désigne un Collaborateur qui possède les droits d’administration du Compte Entreprise
      et
      les droits d’accès à la Console de Gestion.
    </p>

    <p>
      <strong>Numéro(s) :</strong>
      { ' ' }
      désigne le(s) numéro(s) de téléphonie mobile du plan national de numérotation
      affecté(s) à un ou plusieurs Utilisateur(s) au moyen de la Console de Gestion.
    </p>

    <p>
      <strong>ONOFF TELECOM :</strong>
      { ' ' }
      désigne la société ONOFF TELECOM, société par actions simplifiée, immatriculée au
      Registre du Commerce et des Sociétés de Paris sous le numéro 832 235 253 et ayant son siège social au 26 boulevard
      de Bonne Nouvelle 75010, Paris, France, exerçant une activité d’opérateur dans le domaine des télécommunications.
    </p>

    <p>
      <strong>Politique de Protection des Données Personnelles :</strong>
      { ' ' }
      désigne la politique de protection des données
      personnelles définie par ONOFF TELECOM et disponible à l’adresse
      <a href="https://onoffbusiness.com/politique-de-confidentialite/">
        https://onoffbusiness.com/politique-de-confidentialite/
      </a>
      .
    </p>

    <p>
      <strong>Service(s) :</strong>
      { ' ' }
      désigne l’ensemble des services secondaires de gestion de contacts et de
      communications, payants ou gratuits, proposés par ONOFF TELECOM et accessibles à partir d’un accès mobile par
      l’Utilisateur via l’Application conformément aux Conditions d’Utilisation.
    </p>

    <p>
      <strong>Site Web :</strong>
      { ' ' }
      désigne le site accessible à l’adresse onoffbusiness.com, exploité par ONOFF TELECOM.
    </p>

    <p>
      <strong>Utilisateur :</strong>
      { ' ' }
      désigne le titulaire d’un Compte Utilisateur et le détenteur d’une licence
      d’utilisation de l’Application conformément aux Conditions d’Utilisation.
    </p>

    <h3>2. Objet des Conditions d’Utilisation</h3>

    <p>
      Les Conditions d’Utilisation s’appliquent aux Services commercialisés et exploités par ONOFF TELECOM et ont pour
      objet de définir les conditions pratiques et financières aux termes desquelles l'Entreprise va
      pouvoir
      { ' ' }
      <strong>(i)</strong>
      { ' ' }
      créer
      son compte Entreprise,
      { ' ' }
      <strong>(ii)</strong>
      { ' ' }
      lister l'ensemble des Collaborateurs susceptibles de bénéficier des
      Services,
      { ' ' }
      <strong>(iii)</strong>
      leur donner un accès effectif aux Services concernés et
      { ' ' }
      <strong>(iv)</strong>
      { ' ' }
      gérer ultérieurement l'accès de ces
      Collaborateurs
      aux Services.
    </p>

    <p>
      ONOFF TELECOM peut, de plein droit, modifier ses offres de Services et/ou porter des modifications dans les
      Conditions d’Utilisation sans autre formalité que d'en informer préalablement l’Entreprise par tout moyen à sa
      convenance. Dans l’hypothèse où l’Entreprise ne souhaiterait pas accepter l’application des nouvelles Conditions
      d’Utilisation, elle pourra notifier son souhait de mettre fin au Contrat intervenu avec ONOFF TELECOM. Le fait
      pour l’Entreprise de ne pas mettre fin au Contrat emportera son acceptation sans réserve de la nouvelle version
      des Conditions d’Utilisation. Aucun accord, conditions générales d’achat ou d’engagement ne peut, sauf acceptation
      formelle et écrite d’ONOFF TELECOM, prévaloir sur les Conditions d’Utilisation. Toute clause contraire sera, à
      défaut d’acceptation expresse, inopposable à ONOFF TELECOM, quel que soit le moment où elle aura pu être portée à
      la connaissance de cette dernière. Seules les Conditions d’Utilisation, la Grille Tarifaire et la Politique de
      Protection des Données Personnelles constituent les documents contractuels entre ONOFF TELECOM et l’Entreprise ;
      les autres documents ultérieurs formaliseront les étapes nécessaires de l'exécution des Services et de leur suivi.
    </p>

    <p>
      L’accès aux Services est conditionné à l’acceptation préalable des Conditions d’Utilisation. L’Entreprise
      acceptera les Conditions d’Utilisation
      { ' ' }
      <strong>(i)</strong>
      { ' ' }
      en cliquant pour accepter les Conditions d’Utilisation
      ou
      { ' ' }
      <strong>(ii)</strong>
      { ' ' }
      en
      créant son Compte Entreprise.
    </p>

    <h3>3. Compte Entreprise</h3>

    <p>
      Lors de la création du Compte Entreprise par le biais de la Console de Gestion, l'Entreprise fournit un certain
      nombre d’informations pouvant notamment inclure sa raison sociale, son adresse, sa nationalité, son numéro
      d’immatriculation au registre du commerce et des sociétés ou au répertoire des métiers, son numéro de TVA
      intracommunautaire. L’Entreprise s’engage à fournir l’identité et les documents d’identification de son
      représentant légal sur demande d’ONOFF TELECOM. L’Entreprise s’engage à fournir des informations véridiques.
    </p>

    <p>
      Par ailleurs, l'Entreprise communique également à ONOFF TELECOM les nom, prénom, numéro de téléphone et adresse
      mail du responsable du Compte Entreprise (le « Manager »), et définit un mot de passe permettant de sécuriser
      l'accès au Compte Entreprise.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de demander et d’obtenir
      { ' ' }
      <strong>(i)</strong>
      { ' ' }
      un extrait Kbis de moins de trois
      mois ou tout
      document comportant les mentions légalement obligatoires pour la désignation de la personne
      morale,
      { ' ' }
      <strong>(ii)</strong>
      { ' ' }
      une pièce
      officielle attestant de la qualité́ du signataire et de l’étendue de ses pouvoirs et
      { ' ' }
      <strong>(iii)</strong>
      { ' ' }
      un
      justificatif
      d’identité́ de la personne physique dûment mandatée pour souscrire le Contrat au nom de l’Entreprise. ONOFF
      TELECOM se réserve le droit de demander et d’obtenir tout autre document ou information qu’elle juge nécessaire en
      complément et, notamment pour les Utilisateurs, un justificatif d’identité et/ou de domicile ou le numéro mobile
      identifiant l’accès mobile dont dispose l’Utilisateur.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de supprimer à tout moment un Compte Entreprise qui ne respecterait pas les
      dispositions légales applicables, les droits des tiers ou qui ne serait pas conforme aux Conditions d’Utilisation.
      Ainsi en serait-il par exemple d’un Compte Entreprise créé sur la base de fausses informations ou d'adresses mail
      invalides.
    </p>

    <p>
      Une fois créé son Compte Entreprise, l'Entreprise, par l'intermédiaire du
      { ' ' }
      <strong>Manager</strong>
      , pourra définir
      l'ensemble des
      Utilisateurs susceptibles de bénéficier des Services et, le cas échéant, leur affecter des Services.
    </p>

    <p>
      La définition d’un Utilisateur requiert la fourniture du nom de famille, du prénom et de l’adresse e-mail du
      Collaborateur correspondant. L’Entreprise s’engage à fournir des informations véridiques. Une fois défini,
      l’Utilisateur se verra affecté un Compte Utilisateur.
    </p>

    <p>
      L'affectation de Services à un Utilisateur donné entrainera la mise à disposition de l'Utilisateur de l'ensemble
      des Services définis, ainsi que la facturation immédiate des Services concernés à l'Entreprise.
    </p>

    <p>
      Dans le cas d'ajout de certains Services, l'Entreprise se verra immédiatement facturée des services additionnels,
      et ce pour chacun des Utilisateurs concernés. De même, en cas de retrait de certains Services, les services en
      question seront immédiatement résiliés pour chacun des Utilisateurs concernés.
    </p>

    <h3>4. Accès et utilisation de l’Application par les Utilisateurs</h3>

    <p>
      Pour chacun des Utilisateurs, le fonctionnement de l’Application requiert la souscription préalable par
      l’Utilisateur ou l’Entreprise – à leurs frais – d’un Abonnement Opérateur comprenant (i) la possibilité d’émettre
      et de recevoir des appels téléphoniques et/ ou des SMS et (ii) un accès Internet mobile haut débit.
    </p>

    <p>
      Dans l’hypothèse de la souscription d’un Abonnement Opérateur par l’Utilisateur, il est convenu que l’Entreprise
      se porte fort de la souscription et du maintien en vigueur pendant la durée du Contrat de l’Abonnement Opérateur
      par l’Utilisateur.
    </p>

    <p>
      La souscription et le maintien en vigueur de l’Abonnement Opérateur, indispensables à l’utilisation de
      l’Application, relèvent de la seule responsabilité de l’Utilisateur ou de l’Entreprise. L’Utilisateur devra
      s’équiper d’un terminal permettant l’utilisation de l’Application (Smartphone, PDA, tablette ou équivalent) et
      disposant d’un système d’exploitation de version récente et mis régulièrement à jour. L’Utilisateur ou
      l’Entreprise est seul responsable du bon fonctionnement de ses terminaux.
    </p>

    <p>
      L’Entreprise reconnait que l'utilisation effective de l’Application par chacun des Utilisateurs dépend de la
      disponibilité des services de communications et d’Internet haut débit de l'Abonnement Opérateur de chacun de ses
      Utilisateurs. En outre, des limites d’utilisation et des restrictions géographiques spécifiques à l’Application
      peuvent varier. Ainsi, il est conseillé aux Utilisateurs de consulter régulièrement le Site Web afin de connaitre
      plus en détail les restrictions d’utilisation et les modifications récentes apportées à l’Application.
    </p>

    <p>
      Une fois l’Application installée sur son terminal, l’Utilisateur pourra accéder à son Compte Utilisateur avec un
      identifiant et un mot de passe qui lui auront été communiqués préalablement par ONOFF TELECOM par email. Son mot
      de passe est personnel et confidentiel et ne devra être partagé avec aucun tiers afin notamment d’empêcher toute
      utilisation non autorisée du Compte Utilisateur. L’Entreprise se porte fort d’informer l’Utilisateur du fait qu’il
      est seul responsable de la confidentialité de son mot de passe.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de suspendre, refuser ou d’annuler la souscription au Contrat ou de suspendre
      l’exécution du Contrat si l’une des conditions suivantes n’est pas respectée (i) le règlement à échéance de toutes
      les dettes contractées par l’Entreprise auprès d’ONOFF TELECOM au titre d’un autre contrat, (ii) la fourniture de
      tous les justificatifs demandés par ONOFF TELECOM, (iii) l’accord donné par les centres de paiement bancaire
      concernés et (iv) les déclarations exactes de l’Entreprise. Dans ce cas, la demande de souscription sera
      automatiquement refusée ou annulée et l’Entreprise prévenue par mail.
    </p>

    <p>
      En cours de Contrat, ONOFF TELECOM se réserve le droit de procéder et/ou faire procéder à toute investigation
      complémentaire notamment sur les éléments transmis par l’Entreprise et, en cas de doute raisonnable sur
      l’utilisateur du Service ou les documents qui ont été transmis à l’appui de la souscription, de refuser ou
      d’annuler toute souscription au Contrat. Dans ce cas, la demande de souscription sera automatiquement refusée ou
      annulée et l’Entreprise prévenue par mail.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de supprimer à tout moment un Compte Utilisateur qui ne respecterait pas les
      dispositions légales applicables, les droits des tiers ou qui ne serait pas conforme aux Conditions d’Utilisation.
      Ainsi en serait-il par exemple d’un Compte Utilisateur créé sur la base d’un faux nom de famille ou d’une adresse
      e-mail invalide.
    </p>

    <h3>5. Restrictions d’utilisation de l’Application</h3>

    <p>
      La grille tarifaire ainsi que les contenu et limitations de chacun des Services proposés sont détaillés dans la
      Grille Tarifaire.
    </p>

    <p>
      L'Entreprise reconnaît que l’Utilisateur devra faire une utilisation normale de l’Application et des Services
      associés et qu’ONOFF TELECOM pourra mettre en place les mesures visant à prévenir tout détournement manifeste de
      l’objet des Services.
    </p>

    <p>
      ONOFF TELECOM évaluera l’utilisation normale des Services par comparaison aux utilisations des autres
      Utilisateurs. La consommation sera considérée anormale, en ce qu’elle sera considérée constituer un détournement
      de l’objet des Services, si elle s’éloigne substantiellement de la consommation moyenne des Utilisateurs.
    </p>

    <p>
      L’Entreprise est en outre informée que les utilisations suivantes, sans que cette liste ne puisse être considérée
      comme exhaustive, sont considérées comme anormales :
    </p>

    <ul>
      <li>
        l’émission de plus de quinze (15) appels en moins de trois (3) minutes depuis un Compte Utilisateur,
      </li>

      <li>
        l’émission d’appels de plus de 2 heures depuis un compte Utilisateur,
      </li>

      <li>
        l’utilisation de l’Application et/ ou des Services pour la réalisation d’une campagne marketing à des fins
        commerciales, le spamming, la fourniture de services automatisés,
      </li>

      <li>
        la revente ou la tentative de revente des Services à des tiers,
      </li>

      <li>
        l’utilisation de Numéros d’un pays donné sans pouvoir justifier de lien stable impliquant une présence fréquente
        et significative dans ledit pays.
      </li>
    </ul>

    <p>
      Si ONOFF TELECOM détecte une utilisation anormale des Services, elle notifiera à l’Entreprise ou à l’Utilisateur
      concerné le caractère anormal de l’utilisation des Services. ONOFF TELECOM pourra alors suspendre immédiatement
      les Services de façon temporaire ou permanente, sans que sa responsabilité puisse être engagée au titre de cette
      suspension.
    </p>

    <p>
      L’Utilisateur devra utiliser l’Application dans le respect de la loi et de la réglementation applicables
    </p>

    <p>
      L’Utilisateur ne devra en aucun cas faire une utilisation non autorisée de l’Application ou des Services,
      c’est-à-dire contraire à ce qui précède. L’Entreprise est en outre informée que les utilisations suivantes, sans
      que cette liste ne puisse être considérée comme exhaustive, sont considérées comme non autorisées :
    </p>

    <ul>
      <li>
        porter atteinte ou tenter de porter atteinte à l’Application, et notamment :
        <ul>
          <li>
            utiliser un procédé conçu pour déformer, altérer, modifier, désassembler, effacer l’Application, les
            protocoles et les communications, tels que notamment, des virus, chevaux de Troie, un vers, bombes logiques,
            etc. ;
          </li>

          <li>
            altérer ou tenter d’altérer l’Application, sa fonctionnalité ou sa disponibilité ;
          </li>

          <li>
            contourner, désactiver ou perturber les fonctions liées à la sécurité de l’Application et à son intégrité
            (ou celle de tout équipement électronique quel qu’il soit), ou les fonctions qui empêchent ou limitent
            l’utilisation du contenu du Logiciel ;
          </li>

          <li>
            utiliser un système automatisé afin d’accéder à l’Application.
          </li>
        </ul>
      </li>

      <li>
        télécharger et/ou partager des contenus inappropriés (illégaux, portant atteinte à la dignité humaine, à
        caractère sexuel, diffamatoires, injurieux, racistes ou tout autre contenu pouvant engager la responsabilité
        civile ou pénale de la personne qui le publie) ;
      </li>

      <li>
        se livrer à des activités portant atteinte à la vie privée d’autres personnes, notamment en usurpant l’identité
        d’une autre personne ;
      </li>

      <li>
        modifier, intercepter ou surveiller toute communication qui ne lui est pas destinée ;
      </li>

      <li>
        récolter des informations depuis l’Application qui permettraient une identification personnelle y compris les
        noms de comptes ;
      </li>

      <li>
        utiliser l’Application afin d’envoyer, ou d’aider d’autres personnes à envoyer, des messages indésirables. Sont
        considérés comme indésirables l’ensemble des messages et des demandes de contact non sollicitées (« spam », «
        SPIT ou Spam over Internet Telephony », « courrier indésirable » ou des contenus trompeurs et mensongers) et
        envoyées massivement, qu’ils soient sous la forme d’e-mails, d’appels, de messages instantanés ou autres ;
      </li>

      <li>
        utiliser l’Application à des fins d’hameçonnage (ou phishing), d’usurpation d’identité ou de dévoiement ;
      </li>

      <li>
        utiliser l’Application afin d’embarrasser, de menacer, de harceler ou d’envahir la vie privée d’un tiers, ou
        exposer un tiers à des contenus choquants, indécents ou dangereux ;
      </li>

      <li>
        Porter atteinte aux droits de propriété intellectuelle d’ONOFF TELECOM, d’un autre Utilisateur, ou d’un tiers,
        et notamment :
        <ul>
          <li>
            utiliser de quelque manière que ce soit tout contenu soumis à des droits de propriété n’appartenant pas à
            l’Entreprise ou à l’Utilisateur , à moins que celui-ci ne détienne une licence ou la permission expresse du
            propriétaire des droits ;
          </li>

          <li>
            revendre ou commercialiser l’Application, alors que celle-ci est destinée à une utilisation personnelle de
            l’Utilisateur.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      Par ailleurs, il est expressément rappelé à l’Utilisateur et à l’Entreprise que, conformément à la décision de
      l’ARCEP n° 2018-0881 modifiée établissant le plan national de numérotation et ses règles de gestion, tout Numéro
      ne peut être utilisé en tant que « numéro secondaire » pour la fourniture de services téléphoniques ou de
      messagerie par SMS ou MMS, dont le souscripteur est joignable sans aucune restriction par tout utilisateur d’un
      service téléphonique au public ou de SMS ou MMS que dès lors que ces services ne sont utilisables qu’à partir d’un
      accès mobile.
    </p>

    <p>
      Il est également rappelé que ce numéro « secondaire » peut être fourni par un opérateur différent de celui qui
      fournit l’accès mobile à l’Utilisateur.
    </p>

    <p>
      Il est enfin rappelé qu’un Numéro ne peut être affecté qu’à un Utilisateur résidant habituellement ou
      temporairement, ou justifiant de liens stables impliquant une présence fréquente et significative, dans le
      territoire correspondant à ce Numéro. L’Entreprise se porte fort du respect de ces conditions de territorialité et
      s’engage à annuler l’affectation d’un Numéro à tout Collaborateur qui ne remplirait plus les conditions de
      territorialité susvisées.
    </p>

    <p>
      Si ONOFF TELECOM détecte une utilisation non autorisée de l’Application ou des Services, elle le notifiera à
      l’Entreprise ou à l’Utilisateur. ONOFF TELECOM pourra alors suspendre immédiatement les Services de façon
      temporaire ou permanente ou résilier le Contrat, sans que sa responsabilité puisse être engagée au titre de cette
      suspension ou de cette résiliation.
    </p>

    <h3>6. Mise à jour et maintenance</h3>

    <p>
      ONOFF TELECOM pourra à tout moment faire des mises à jour de l’Application et de la Console de Gestion.
    </p>

    <p>
      Ces mises à jour pourront avoir pour objet de maintenir la compatibilité logicielle, proposer de nouvelles
      fonctionnalités ou versions de l’Application ou de la Console de Gestion, fournir des mises à jour de sécurité ou
      des corrections de bugs, etc. ONOFF TELECOM se réserve le droit de vérifier les versions de l’Application et
      télécharger les modifications de configuration et les mises à jour de l’Application. ONOFF TELECOM ne garantit pas
      que l’Application mise à jour soit toujours compatible avec les versions plus anciennes de l’Application. ONOFF
      TELECOM ne garantit pas non plus de continuer à prendre en charge une version du système d’exploitation ou de
      l’appareil pour lequel l’Utilisateur dispose d’une Licence.
    </p>

    <p>
      L’Entreprise reconnaît que les Utilisateurs devront en permanence utiliser une version à jour de l’Application.
    </p>

    <p>
      ONOFF TELECOM est susceptible de modifier l’Application ou la Console de Gestion à tout moment et à cet effet de
      réaliser des opérations de maintenance ou de mise à niveau de l’Application ou de la Console de Gestion, ou de
      l’infrastructure sous-jacente permettant l’utilisation de l’Application ou de la Console de Gestion. ONOFF TELECOM
      pourra dans ce cadre suspendre temporairement ou limiter l’utilisation de tout ou partie de l’Application ou des
      Services. Sauf en cas d’urgence, les interruptions temporaires seront, dans la mesure du possible, notifiées sur
      le Site Web avant qu’elles n’interviennent. ONOFF TELECOM ne sera en aucun cas redevable de dommages et intérêts
      envers l’Entreprise en cas de suspension ou de limitation de l’utilisation de l’Application en raison de la
      conduite d’opérations de maintenance.
    </p>

    <h3>7. Description des Services</h3>

    <p>
      Une fois les étapes d’inscription réalisées par son Entreprise, l’Utilisateur pourra obtenir auprès d’ONOFF
      TELECOM selon les instructions données à ONOFF TELECOM par son Entreprise et les Services qui lui auront été
      affectés au travers de la Console de Gestion, un ou plusieurs Numéros et/ou un ou plusieurs forfaits d'appel
      ("Forfaits"), et bénéficier des fonctionnalités associées. L’affectation d’un Numéro donne accès à l’ensemble des
      Services liés. Les Numéros permettent d’émettre et de recevoir des appels téléphoniques et/ ou le cas échéant des
      SMS et/ ou des MMS vers et de toute personne disposant d’un numéro de téléphone sous réserve des limitations
      énoncées au paragraphe suivant concernant les appels vers certaines classes de numéros.
    </p>

    <p>
      Les Services ne se substituant en aucun cas au service primaire de téléphonie mobile fourni par l’Abonnement
      Opérateur, l’Entreprise reconnait et accepte que les Numéros ne permettent pas d’émettre des appels vers tous les
      numéros. En conséquence, les appels ne pouvant être émis depuis l’Application (c’est-à-dire notamment mais non
      exhaustivement les appels vers les numéros spéciaux et les numéros d’urgence) devront être émis directement depuis
      le numéro associé à l’Abonnement Opérateur de l’Utilisateur.
    </p>

    <p>
      Chaque Numéro peut être activé ou désactivé temporairement via l’interface de l’Application. L’Utilisateur peut,
      de la même manière, activer et désactiver les appels et les SMS de chaque Numéro.
    </p>

    <h3>8. Conditions d’utilisation des Services</h3>

    <p>
      L’accès et l’utilisation des Services par l'Utilisateur requièrent (i) l’utilisation de l’Abonnement Opérateur de
      l'Utilisateur, et en particulier de la carte SIM associée à l’Abonnement Opérateur, du crédit téléphonique
      correspondant et de l’accès au réseau Internet et (ii) un terminal permettant l’utilisation des Services
      (Smartphone, PDA, tablette ou équivalent) et disposant d’un système d’exploitation de version récente et mis à
      jour périodiquement.
    </p>

    <p>
      ONOFF TELECOM rappelle que la disponibilité des Services dépend de la disponibilité des services de communications
      et d’Internet haut débit de l'Abonnement Opérateur de chaque Utilisateur.
    </p>

    <p>
      En se voyant affecter un Numéro, l'Entreprise et l’Utilisateur acceptent ce qui suit :
    </p>

    <ul>
      <li>
        ni l'Entreprise ni l’Utilisateur ne disposent d’aucun droit de propriété sur le(s)Numéro(s), pas plus que d’un
        droit à la conservation ou au maintien définitif du/des Numéro(s) ;
      </li>

      <li>
        l’Utilisateur devra utiliser l’Application dans le respect de la loi et de la réglementation applicables et des
        Conditions d’Utilisation ;
      </li>

      <li>
        l’Utilisateur devra se conformer en permanence aux règles applicables en matière de numérotation et aux
        exigences particulières d’affectation des Numéros ainsi qu’à toutes les instructions qu’ONOFF TELECOM pourrait
        lui adresser en ce qui concerne le(s) Numéro(s) ;
      </li>

      <li>
        l’Entreprise dispose d’un droit d’utilisation révocable du Numéro, excluant notamment sa cession, sa
        sous-location, son transfert, sa portabilité ou plus généralement tout acte de disposition ou de sûreté.
      </li>
    </ul>

    <p>
      ONOFF TELECOM se réserve le droit de modifier les conditions d’accès aux Numéros et de fourniture des Services, en
      particulier en cas de changement des dispositions légales et réglementaires applicables ou pour prendre en compte
      toute décision administrative ou judiciaire qui serait applicable aux modalités de fourniture des Numéros et des
      Services associés.
    </p>

    <h3>9. Qualité du Service</h3>

    <p>
      ONOFF TELECOM applique les mesures de sécurité nécessaires à la prévention des incidents et met en œuvre les
      moyens lui permettant de réagir à un incident pour limiter l’indisponibilité des Services et l’impact sur les
      données des Utilisateurs.
    </p>

    <p>
      ONOFF TELECOM s’engage à atteindre, en zone couverte, les niveaux de service suivants :
    </p>

    <ul>
      <li>
        une disponibilité des Services dans un délai maximum de six (6) heures à compter de la création du Compte
        Utilisateur et de l’affectation des Services à l’Utilisateur,
      </li>

      <li>
        une disponibilité mensuelle des Services au moins égale à 90 %, la disponibilité du Service s’entendant de la
        possibilité matérielle d’émettre ou de recevoir une communication,
      </li>

      <li>
        un rétablissement des Services dans un délai maximum de quarante-huit (48) heures à compter de la notification
        de l’interruption des Services auprès du service d’assistance client (le «
        { ' ' }
        <strong>Support</strong>
        { ' ' }
        ») d’ONOFF
        TELECOM accessible
        par le Site Web. L’interruption du Service s’entend de l’impossibilité matérielle d’émettre ou de recevoir une
        communication.
      </li>
    </ul>

    <p>
      Les niveaux de service s’appliquent lorsque les conditions suivantes sont cumulativement remplies : (i)
      l’Utilisateur se situe dans une zone habituellement couverte par le Service et son Abonnement Opérateur et (ii)
      dispose d’un terminal compatible avec l’Application, allumé et en parfait état de fonctionnement.
    </p>

    <p>
      Dans l’hypothèse où ONOFF TELECOM n’atteindrait pas l’un des niveaux de service susmentionnés, l'Entreprise
      disposera d’un délai d’un (1) mois pour adresser une demande de compensation précisant l'Utilisateur, l’incident
      concerné et sa durée auprès du Support. ONOFF TELECOM s’engage à traiter les demandes de compensation (i) dans les
      cinq (5) jours ouvrés suivant leur réception en ce qui concerne la conformité de la demande de l'Entreprise aux
      conditions détaillées au présent article et (ii) dans le mois qui suit leur réception pour leur complet examen. Si
      la demande est justifiée, ONOFF TELECOM attribuera à l'Entreprise affectée une compensation équivalente à un (1)
      mois d’abonnement au Service affecté.
    </p>

    <p>
      Ces demandes de compensation ne sont toutefois pas recevables dans les cas suivants :
    </p>

    <ul>
      <li>
        en cas d’absence de demande de compensation formulée par l’Entreprise dans le délai d’un (1) mois du non-respect
        des niveaux de services susmentionnés,
      </li>

      <li>
        en cas de faute de l’Entreprise et/ou de l’Utilisateur concerné ou de mauvaise utilisation par l’Entreprise
        et/ou l’Utilisateur et/ou ses correspondants du Service,
      </li>

      <li>
        en cas de mauvais paramétrage de l’Application,
      </li>

      <li>
        en cas d’utilisation non-conforme de l’Application,
      </li>

      <li>
        en cas de transmission des signaux radioélectriques affectée par les contraintes ou les limites des normes
        techniques imposées à l’opérateur auprès duquel l’Abonnement Opérateur est souscrit ou à l’(aux) opérateur(s)
        partenaire(s) d’ONOFF TELECOM par les autorités réglementaires ou les groupements normatifs compétents,
      </li>

      <li>
        en cas d’opération de maintenance d’ONOFF TELECOM ou du ou des opérateur(s) partenaire(s) d’ONOFF TELECOM,
      </li>

      <li>
        en cas de perturbation ou d’interruption non directement imputable à ONOFF TELECOM,
      </li>

      <li>
        en cas de perturbation et/ou d’indisponibilité totale ou partielle, et/ou d’interruption de tout ou partie des
        services proposés sur les réseaux de l’opérateur auprès duquel l’Abonnement Opérateur est souscrit,
      </li>

      <li>
        en cas de force majeure.
      </li>
    </ul>

    <h3>10. Hébergement du contenu des communications</h3>

    <p>
      ONOFF TELECOM s’engage à assurer la sécurité, la confidentialité et l’intégrité des communications passées par
      l’Application conformément aux obligations légales et réglementaires applicables.
    </p>

    <p>
      Néanmoins, les contenus stockés, utilisés, transmis et reçus par l’Utilisateur grâce à l’Application le sont sous
      son entière responsabilité. Il appartient à l’Utilisateur de veiller à ne pas enfreindre les dispositions légales
      et réglementaires en vigueur, en particulier relatives aux droits de propriété intellectuelle attachés à certains
      contenus.
    </p>

    <p>
      L’Entreprise et ses Utilisateurs sont seuls responsables de l’usage qu’ils font ou qu’ils entendent faire des
      fonctionnalités de l’Application et du Contenu Utilisateur. ONOFF TELECOM n’assure ni le contrôle ni la promotion
      du Contenu Utilisateur. ONOFF TELECOM n’assume aucune responsabilité quant à ce Contenu Utilisateur, quel qu’il
      soit, y compris, et sans s’y limiter, les médias partagés via l’utilisation de l’Application. En outre,
      l’Entreprise reconnait et accepte qu’ONOFF TELECOM n’exerce aucun contrôle a priori sur le Contenu Utilisateur et
      qu’il n’est pas tenu à une obligation générale de surveillance de ces contenus. De même, bien qu’elle ait un accès
      au contenu Utilisateur, ONOFF TELECOM n’exerce aucun contrôle des sites et des sources externes vers lesquels
      redirigent des liens hypertextes accessibles via l’Application. ONOFF TELECOM est autorisée à communiquer tout
      contenu Utilisateur à la requête de toute autorité administrative ou judiciaire.
    </p>

    <p>
      Tout Utilisateur ou Entreprise a la possibilité de notifier à ONOFF TELECOM tout Contenu Utilisateur illicite ou
      portant atteinte aux droits de l’Utilisateur afin d’en demander le retrait. L’Entreprise sera, dans ce cas, seule
      responsable de la plainte qu’elle aura déposée auprès d’ONOFF TELECOM. L’Entreprise admet et convient que toute
      plainte déposée par elle pourra être utilisée dans le cadre d’une éventuelle procédure judiciaire. L’Entreprise
      admet et convient également que toute information inexacte, fausse ou trompeuse pourra engager sa responsabilité
      civile et pénale.
    </p>

    <p>
      Toute plainte ou notification accusant à tort des Contenus Utilisateurs de porter atteinte aux droits de
      l’Entreprise notifiante ou de ses Utilisateurs ou aux droits de tiers, en ayant connaissance de l’inexactitude de
      cette déclaration, rend l’Entreprise notifiante passible de poursuites. L’Entreprise notifiante pourrait ainsi
      être condamnée au versement de dommages et intérêts, aux dépens ou/et aux frais de justice supportés par celui-ci,
      ainsi que par tout titulaire de droits ayant subi un préjudice en raison de la confiance accordée par ONOFF
      TELECOM à cette déclaration inexacte. L’Entreprise notifiante, dans ce cas, pourra également, le cas échéant,
      faire l’objet de poursuites pénales.
    </p>

    <p>
      L’Entreprise déclare et garantit avoir informé chacun de ses Collaborateurs des éléments suivants :
    </p>

    <ul>
      <li>
        un Utilisateur ne doit publier ou télécharger un Contenu Utilisateur que s’il possède les consentements,
        autorisations et licences nécessaires.
      </li>

      <li>
        un Utilisateur ne doit utiliser, publier ou inclure d’élément personnel à un tiers au sein du Contenu
        Utilisateur que s’il dispose de l’autorisation, du consentement ou de la permission exprès de cette personne à
        cet effet.
      </li>
    </ul>

    <p>
      En publiant ou en téléchargeant du Contenu Utilisateur, l'Entreprise et chacun des Utilisateurs conviennent et
      acceptent d’accorder à ONOFF TELECOM sur ce contenu une licence mondiale non exclusive, irrévocable, libre de
      droits, susceptible de sous-licence et transférable de tous les droits d’utilisation, de modification, de
      représentation, de traduction, d’exploitation, d’édition, d’inclusion, d’enregistrement, d’adaptation, de
      reproduction et autres. Cette licence est accordée pour toute la durée maximale de protection par les droits de
      propriété intellectuelle du Contenu Utilisateur publié ou téléchargé par l’Utilisateur.
    </p>

    <p>
      L'Entreprise et chacun des Utilisateurs garantissent ONOFF TELECOM de toute revendication intentée par des tiers
      ou d’autres Utilisateurs au motif que le Contenu Utilisateur constituerait une violation des droits de propriété
      intellectuelle ou industrielle revendiqués par des tiers ou d’autres Utilisateurs, ONOFF TELECOM ne pouvant être
      recherché ou inquiété à ce sujet.
    </p>

    <p>
      En vertu du droit applicable, ONOFF TELECOM se réserve le droit, bien qu’il n’ait aucune obligation de contrôler
      le Contenu Utilisateur, d’accéder audit Contenu Utilisateur à tout moment et sans notification préalable, afin de
      vérifier le respect des Conditions d’Utilisation, de vérifier toute violation ou violation présumée des droits de
      tiers, de vérifier la responsabilité d’Utilisateurs suite aux plaintes d’individus et/ou aux demandes des
      autorités, et de prendre les mesures nécessaires, y compris le retrait du Contenu Utilisateur, la suspension
      temporaire ou la résiliation des Services.
    </p>

    <h3>11. Propriété intellectuelle</h3>

    <p>
      L’Application contient des informations exclusives et confidentielles protégées par les lois et traités sur la
      propriété intellectuelle. ONOFF TELECOM conserve la propriété exclusive de l’Application et l’Entreprise et/ou
      l’Utilisateur s’interdisent, directement, indirectement ou par l’intermédiaire de tiers, de porter atteinte,
      mettre en péril ou limiter, par quelque mesure que ce soit, les droits de propriété intellectuelle dont dispose
      ONOFF TELECOM sur l’Application. De même toute reproduction, représentation, adaptation, modification, traduction,
      transformation, diffusion, intégration, dans un autre matériel, exploitation commerciale ou non et/ou
      réutilisation de quelque manière que ce soit de tout ou partie des éléments composant l’Application est
      strictement interdite. ONOFF TELECOM, les marques de commerce et les logos associés sont des marques commerciales
      propriété d’ONOFF TELECOM.
    </p>

    <p>
      L’Utilisateur bénéficie, sous réserve de l’acceptation et du respect des Conditions d’Utilisation, d’un droit
      d’usage limité, non exclusif, non transférable, non cessible, à titre gratuit, afin de télécharger et installer
      l’Application sur un terminal, de type Smartphone ou tablette et d’utiliser à titre personnel l’Application par le
      biais de son Compte Utilisateur. Ce droit est conféré à l’Utilisateur pour toute la durée du présent Contrat et
      sur l’ensemble du territoire dans lequel ONOFF TELECOM commercialise les Services.
    </p>

    <p>
      L’Utilisateur s’interdit de :
    </p>

    <ul>
      <li>
        concéder la licence, vendre, céder, louer, exporter, importer, distribuer ou transférer ou accorder des droits à
        un tiers sur l’Application ;
      </li>

      <li>
        de quelque manière que ce soit, modifier, copier, décompiler, effectuer l’ingénierie inverse, désassembler tout
        ou partie des éléments composant l’Application, ou encore tenter de dériver le code source, ou créer des œuvres
        dérivées à partir de tout ou partie de l’Application ou de ses mises à jour, sauf dans les cas où la loi
        applicable en disposerait autrement ;
      </li>

      <li>
        directement ou indirectement, créer une œuvre dérivée, rechercher le code source ou des protocoles des logiciels
        de l’Application (sauf dans la mesure permise par la loi) ;
      </li>

      <li>
        supprimer, masquer ou modifier les mentions de droit des marques ou d’autres mentions de propriété incluses dans
        l’Application.
      </li>
    </ul>

    <p>
      Toute action ci-dessus énoncée sera considérée comme une violation des droits d’ONOFF TELECOM. Toute utilisation
      non conforme que l’Application pourra entrainer des poursuites judiciaires.
    </p>

    <p>
      Cet article régira toute mise à jour fournie par ONOFF TELECOM qui remplace et/ou s’ajoute à l’Application, sauf
      si celle-ci est accompagnée d’une licence distincte.
    </p>

    <h3>12. Données personnelles</h3>

    <p>
      La collecte et l’utilisation des données personnelles de l’Utilisateur et du Manager sont définies à la Politique
      de Protection des Données Personnelles.
    </p>

    <p>
      En raison de l’objet du Service et de la confidentialité associée, l’inscription à l’annuaire universel ne sera
      pas effectuée par ONOFF TELECOM. Toutefois, l’Utilisateur peut solliciter son inscription à l’annuaire universel
      par demande expresse adressée auprès du Support d’ONOFF TELECOM. Dans ce cas, l’Utilisateur communiquera, sous sa
      responsabilité, ses coordonnées (numéro de téléphone et nom, prénom ou initiales sous réserve d’homonymie, raison
      sociale, adresse postale complète ou non, adresse électronique, profession ou activité) pour qu’elles figurent
      gratuitement dans les listes de ONOFF TELECOM destinées aux éditeurs d’annuaires et de services de renseignements.
    </p>

    <h3>13. Conditions financières</h3>

    <p>
      Tous les tarifs applicables aux Services et en particulier applicables aux Numéros, aux Forfaits d'appel et aux
      Crédits ONOFF sont mentionnés, régulièrement mis à jour et disponibles via la Console de Gestion et à l’adresse :
      onoffbusiness.com/tarifs. Les Services fournis aux Utilisateurs par ONOFF TELECOM sont facturés à l'Entreprise
      conformément au prix en vigueur au jour de la souscription du Service concerné.
    </p>

    <p>
      Toute baisse générale de tarifs pourra être réalisée par ONOFF TELECOM sans délai particulier. Lorsqu’une remise
      particulière a été accordée lors de la souscription d’un Service, ONOFF TELECOM se réserve le droit de ne plus
      appliquer la remise tarifaire initiale sur la nouvelle tarification.
    </p>

    <p>
      En cas de modification à la hausse des tarifs applicables, ONOFF TELECOM en informera l'Entreprise au moins un (1)
      mois avant l’entrée en vigueur du tarif modifié. L'Entreprise pourra alors résilier le Service concerné par
      l'intermédiaire de la Console de Gestion et dans les conditions définies ci-dessous à l’article 15.
    </p>

    <p>
      La souscription à chaque Numéro est soumise au paiement d’un prix forfaitaire variable selon la catégorie et la
      période de souscription dudit Numéro.
    </p>

    <p>
      L’abonnement aux Services se renouvellera automatiquement à la fin de chaque période, pour l'ensemble des
      Utilisateurs, sauf résiliation du Compte Entreprise. Le renouvellement de l’abonnement entrainera facturation et
      paiement du montant correspondant.
    </p>

    <p>
      L'Entreprise règlera mensuellement et d'avance l'ensemble des Services souscrits. A cette fin, l'Entreprise devra
      veiller à ce que son Compte Entreprise référence un ou plusieurs moyens de paiement valides parmi ceux acceptés en
      paiement par ONOFF TELECOM.
    </p>

    <p>
      Tout achat ou renouvellement de Services fera l'objet d'une facture sous format électronique mise à disposition de
      l'Entreprise par l'intermédiaire de la Console de Gestion.
    </p>

    <p>
      Le défaut de paiement à l’échéance par l’Entreprise d’une facture entraînera de plein droit et sans formalité :
    </p>

    <ul>
      <li>
        conformément aux dispositions de l’article L. 441-6 du Code de commerce, la facturation de pénalités de retard
        dont le montant sera calculé sur le montant toutes taxes comprises de la facture échue, étant précisé que le
        taux de ces pénalités de retard sera (i) dû par l’Entreprise du seul fait de l'échéance au terme contractuel,
        (ii) dû par l’Entreprise nonobstant tous dommages et intérêts complémentaires auxquels pourrait prétendre ONOFF
        TELECOM du fait du non-paiement en cause et (iii) égal au taux d'intérêt appliqué par la Banque centrale
        européenne à son opération de refinancement la plus récente majoré de 10 points de pourcentage et ce, dans les
        conditions définies à l’article L. 441-6 du Code de commerce, ce taux d’intérêt ne pouvant toutefois être
        inférieur à trois fois le taux d'intérêt légal,
      </li>

      <li>
        le paiement d’une indemnité forfaitaire de recouvrement, nonobstant l’absence de procédure de recouvrement, fixé
        à un montant de quarante (40,00) euros en application des dispositions des articles L. 441-6 et D. 441-5 du Code
        de commerce.
      </li>

      <li>
        Aux termes de l’article L.441- 6 du Code de commerce, ONOFF TELECOM pourra demander toute indemnisation
        complémentaire, lorsque les frais qu’elle a exposés sont supérieurs au montant de l’indemnité forfaitaire
        (notamment frais d’un avocat ou d’une société de recouvrement de créances).
      </li>

      <li>
        l'annulation de toutes les remises, ristournes, rabais et autres avantages consentis par ONOFF TELECOM au profit
        de l’Entreprise et/ou des Utilisateurs.
      </li>
    </ul>

    <p>
      En cas de désaccord sur le montant d'un paiement, celui-ci est effectué sur la base provisoire des sommes admises
      et établies par ONOFF TELECOM. Les sommes qui seront dues à ONOFF TELECOM au titre du Contrat ne seront pas
      compensables à due concurrence avec toutes autres sommes ou créances dont ONOFF TELECOM serait redevable à l’égard
      de l’Entreprise.
    </p>

    <p>
      L'Entreprise disposera de quatre-vingt-dix (90) jours suivant la date de facturation pour contester les sommes qui
      lui ont été facturées en y apportant toutes les justifications nécessaires. Passé ce délai, l'Entreprise ne pourra
      plus solliciter aucun remboursement.
    </p>

    <p>
      ONOFF TELECOM se réserve le droit de refuser toute demande de remboursement si elle considère (i) que la demande
      de l'Entreprise n’est pas fondée ou (ii) que l'Entreprise n’a pas respecté les Conditions d’Utilisation.
    </p>

    <p>
      Toute réduction de la facture contestée donnera lieu à l’émission d’un avoir venant en déduction de la facture
      suivante.
    </p>

    <p>
      En application de l’article L. 34-2 du Code des Postes et Communications Électroniques, la prescription est
      acquise pour toutes demandes en restitution du prix des prestations de communications électroniques présentées par
      l’Entreprise après un délai d'un an à compter du jour du paiement.
    </p>

    <h3>14. Modifications des Conditions d’Utilisation</h3>

    <p>
      ONOFF TELECOM informe l'Entreprise de toute modification des Conditions d’Utilisation préalablement à leur entrée
      en vigueur. Si l'Entreprise refuse que cette modification s’applique à ses Services en cours, elle doit résilier
      son Contrat dans le mois suivant l’information de la modification, dans les conditions définies ci-dessous à
      l’article 15. A défaut de résiliation dans ce délai, les Conditions d’Utilisation modifiées s’appliqueront
      automatiquement à l’issue de ce délai.
    </p>

    <h3>15. Date d’effet, durée et résiliation</h3>

    <p>
      Le Contrat prend effet à la date de création du Compte Entreprise et restera en vigueur jusqu’à la réalisation
      cumulatives des deux conditions suivantes : (i) la résiliation par l’une des Parties de l’ensemble des Services
      fournis à l’ensemble des Utilisateurs et (ii) la suppression par l'Entreprise de son Compte Entreprise.
    </p>

    <p>
      A la date de prise d’effet de la résiliation du Contrat par l'Entreprise, celle-ci aura les conséquences suivantes
      :
    </p>

    <ul>
      <li>
        toutes les licences et droits d’utilisation de l’Application prendront fin ;
      </li>

      <li>
        chacun des Utilisateurs devra cesser d’utiliser l’Application;
      </li>

      <li>
        chacun des Utilisateurs devra supprimer l’Application de tous les disques durs, réseaux et supports de stockage
        et en détruire toutes les copies en sa possession, ou sous son contrôle ;
      </li>

      <li>
        ONOFF TELECOM pourra bloquer l’accès aux Comptes Utilisateurs et/ ou à l’Application ;
      </li>

      <li>
        ONOFF TELECOM pourra effacer les données associées aux Comptes Utilisateurs.
      </li>
    </ul>

    <p>
      La résiliation d’un Service particulier aura les mêmes conséquences que celles susmentionnées, lesquelles seront
      limitées au seul Service résilié.
    </p>

    <p>
      Chaque Partie pourra en outre résilier le Contrat pour faute de l’autre Partie huit (8) jours après l’envoi d’une
      mise en demeure restée vaine, celle-ci résultant du seul manquement de la partie fautive. Il est convenu que les
      cas suivants pourront entrainer la résiliation de plein droit du Contrat par ONOFF TELECOM :
    </p>

    <ul>
      <li>
        si l'Entreprise a contrevenu aux Conditions d’Utilisation ;
      </li>

      <li>
        si les informations renseignées par l'Entreprise dans son Compte Entreprise sont inexactes ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne une utilisation illégale ou frauduleuse de l’Application ou contraire aux droits de
        tiers ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne que l'Entreprise essaye de tirer profit indûment de l’une des politiques d’ONOFF
        TELECOM, quelles qu’elles soient, ou de les utiliser à mauvais escient.
      </li>
    </ul>

    <p>
      ONOFF TELECOM pourra mettre fin à sa relation avec l'Entreprise, à tout moment sous réserve du respect d’un
      préavis minimum d’un (1) mois avant l’arrivée du terme de la durée d’engagement.
    </p>

    <p>
      Si ONOFF TELECOM décide de ne plus proposer un Service particulier, il pourra résilier ledit Service sans
      pénalité, sous réserve du respect d’un préavis minimum d’un mois.
    </p>

    <p>
      Toute résiliation d’ONOFF TELECOM sera notifiée à l'Entreprise par voie d’e-mail.
    </p>

    <p>
      L'Entreprise comprend et accepte que le fait pour un Utilisateur de ne pas utiliser un Numéro dans les conditions
      définies dans les Conditions Générales d'Utilisation entraine la résiliation automatique et de plein droit du
      Numéro ainsi que des données relatives à l’utilisation du Numéro (par exemple : SMS, journal des appels, messages
      vocaux, etc.).
    </p>

    <h3>16. Suspension</h3>

    <p>
      Sans préjudice de la possibilité de résilier le Contrat, ONOFF TELECOM pourra suspendre les Services ou l’accès à
      l’Application de l'un des Utilisateurs sur simple information écrite de l’Utilisateur concerné dans les cas
      suivants :
    </p>

    <ul>
      <li>
        si l’Utilisateur a contrevenu aux Conditions d’Utilisation ;
      </li>

      <li>
        si les informations renseignées pour l’Utilisateur dans son Compte Utilisateur sont inexactes, incomplètes ou si
        le numéro mobile de l’Utilisateur fourni par l’opérateur auprès duquel l’Abonnement Opérateur a été souscrit
        n’est plus valide ;
      </li>

      <li>
        si les conditions de territorialité requises pour l’attribution du numéro à l’Utilisateur ne sont plus remplies
        ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne une utilisation illégale ou frauduleuse ou anormale de l’Application ou contraire aux
        droits de tiers ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne que l’Utilisateur essaye de tirer profit indûment de la politique de remboursement ou
        de l’une des autres politiques d’ONOFF TELECOM, quelles qu’elles soient, ou de les utiliser à mauvais escient ;
      </li>

      <li>
        si ONOFF TELECOM soupçonne que le Compte Utilisateur fait l’objet d’une utilisation frauduleuse par un tiers.
      </li>
    </ul>

    <p>
      ONOFF TELECOM est également susceptible d’interrompre l’utilisation de l’Application dans certaines conditions
      incluant, de façon non-exhaustive, l’impossibilité d’assurer le Service, des problèmes d’origine externe qui
      rendent la mise en place du Service difficile ou imprudente, les progrès technologiques, la nécessité de modifier
      l’Application ou les Services.
    </p>

    <h3>17. Responsabilité</h3>

    <p>
      ONOFF TELECOM s’engage à apporter toute la compétence et le soin nécessaires à la fourniture des Services et à
      mettre en œuvre toutes les dispositions nécessaires afin d’assurer les Services de manière permanente et continue.
      A ce titre ONOFF TELECOM n’a qu’une obligation de moyens.
    </p>

    <p>
      Au regard des dispositions légales pertinentes et applicables, l’Application est fournie « en l’état » et sera
      utilisée aux propres risques de l’Utilisateur, sans aucune garantie. En ce sens, l’Entreprise et/ou l’Utilisateur
      sont seuls responsables de la préservation, de la sécurité et de l’intégrité des données, matériels et logiciel,
      ainsi que de la confidentialité de ses mots de passe et identifiants de Compte Utilisateur et autres dans le cadre
      de l’utilisation des Services.
    </p>

    <p>
      ONOFF TELECOM n’assume aucune responsabilité envers tout tiers ou envers l’Utilisateur et l’Entreprise concernant
      les contenus, ces derniers étant les seuls responsables du Contenu Utilisateur soumis, créé, affiché ou publié
      grâce à l’Application.
    </p>

    <p>
      ONOFF TELECOM fait ses meilleurs efforts pour assurer la confidentialité des informations et des contenus publiés
      grâce à l’Application.
    </p>

    <p>
      ONOFF TELECOM ne garantit pas que l’Application sera toujours disponible, accessible, ininterrompue, fiable, et
      exempte d’erreurs. En outre, aucune garantie n’est offerte concernant les connexions ou transmissions internet, ou
      à la qualité des appels passés au moyen de l’Application.
    </p>

    <p>
      S’il advenait qu’un tiers porte plainte contre ONOFF TELECOM en relation avec, ou à la suite de (i) tout
      manquement de la part de l’Utilisateur ou de l’Entreprise aux Conditions d’Utilisation, (ii) tout manquement de la
      part de l’Utilisateur ou de l’Entreprise à toute loi ou réglementation applicable, (iii) toute violation de la
      part de l’Utilisateur ou de l’Entreprise des droits des tiers (y compris les droits de propriété intellectuelle),
      (iv) ses Contenus Utilisateur ou (v) sa plainte concernant tout Contenu Utilisateur, l'Entreprise et l’Utilisateur
      garantiront solidairement ONOFF TELECOM de tous dommages, responsabilités, pertes, condamnation, coûts et dépenses
      (y compris les frais juridiques et autres coûts raisonnables) qui en découleraient.
    </p>

    <p>
      Sous réserve de ce qui précède et conformément à la loi applicable, ONOFF TELECOM ne saurait être tenue
      responsable envers l'Entreprise ou l’Utilisateur, qu’ONOFF TELECOM ait ou non été informée de la possibilité de
      tels dommages ou perte, pour :
    </p>

    <ul>
      <li>
        des dommages indirects et notamment tout dommage, perte, ou corruption de données;
      </li>

      <li>
        des dommages résultant des faits des tiers ou des cas de force majeure;
      </li>

      <li>
        toute réclamation, tout dommage ou perte résultant de, ou lié à :
        <ul>
          <li>
            l’incapacité de l’Utilisateur à utiliser tout ou partie de l’Application ou de sa fonctionnalité ;
          </li>

          <li>
            l’incapacité de l’Utilisateur à prendre les dispositions nécessaires pour rentrer en contact avec les
            services d’urgence ;
          </li>

          <li>
            l’incapacité de l’Utilisateur à fournir des informations précises sur l’emplacement physique à un service
            d’urgence ;
          </li>

          <li>
            la conduite des agents de service d’urgence tiers et des centres d’appels avec lesquels l’Utilisateur est
            mis en relation.
          </li>
        </ul>
      </li>
    </ul>

    <p>
      Les Services ne se substituant en aucun cas au service primaire de téléphonie mobile fourni par l’opérateur auprès
      duquel un Abonnement Opérateur a été souscrit, l’Entreprise reconnait et accepte que les Numéros ne permettent pas
      d’émettre des appels vers toutes les classes de numéros. En conséquence, les appels ne pouvant être émis depuis
      l’Application (c’est-à-dire notamment les appels vers les numéros spéciaux, les numéros internationaux et les
      numéros d’urgence) devront être émis directement depuis le téléphone de l’Utilisateur. ONOFF TELECOM se réserve le
      droit de permettre aux Utilisateurs d’émettre des appels vers des classes de numéros additionnelles
      postérieurement au lancement des Services.
    </p>

    <p>
      En complément de ce qui précède, ONOFF TELECOM ne saurait être tenue responsable envers l'Entreprise ou
      l’Utilisateur, que ONOFF TELECOM ait ou non été informée de la possibilité de tels dommages ou perte, pour :
    </p>

    <ul>
      <li>
        tout dommage indirect, et notamment, toute perte de revenu, de contrats, de bénéfices réels ou prévus, de
        chance, d’image de marque ou de réputation, de clientèle ou de de chiffre d’affaires ;
      </li>

      <li>
        tout dommage ou toute corruption de données ;
      </li>

      <li>
        toute réclamation, tout dommage ou perte résultant de, ou lié à :
      </li>

      <li>
        l’incapacité de l’Utilisateur à utiliser tout ou partie de l’Application ou de sa fonctionnalité ;
      </li>

      <li>
        l’incapacité de l’Utilisateur à prendre les dispositions nécessaires pour rentrer en contact avec les services
        d’urgence
      </li>

      <li>
        l’incapacité de l’Utilisateur à fournir des informations précises sur l’emplacement physique à un service
        d’urgence ;
      </li>

      <li>
        la conduite des agents de service d’urgence tiers et des centres d’appels avec lesquels l’Utilisateur est mis en
        relation.
      </li>

      <li>
        tout dommage au-delà d’un montant, tous chefs de préjudices confondus, correspondant au montant total payé par
        l’Utilisateur pour les Services au cours de la période de douze (12) mois précédant immédiatement la date de
        survenance du fait à l’origine de la demande en cause.
      </li>
    </ul>

    <p>
      Dans la mesure où l’Entreprise démontrerait avoir subi un préjudice direct, la responsabilité d’ONOFF TELECOM sera
      expressément limitée à cent pour cent (100,00%) des sommes effectivement perçues par d’ONOFF TELECOM au titre du
      Contrat lors des six (6) derniers mois précédents la survenance dudit préjudice.
    </p>

    <p>
      Les actions en responsabilité à l’encontre d’ONOFF TELECOM se prescrivent par deux ans à compter du fait
      générateur du préjudice.
    </p>

    <h3>18. Force Majeure</h3>

    <p>
      La responsabilité d’ONOFF TELECOM ne peut pas être engagée au cas où l’inexécution résulte d’un cas de force
      majeure.
    </p>

    <p>
      Sont considérés comme cas de force majeure, dès lors qu’ils ne sont pas le fait d’ONOFF TELECOM et qu’ils
      échappent à son contrôle, les intempéries exceptionnelles, les catastrophes naturelles, les incendies et
      inondations, la foudre, les surtensions électroniques, les attentats, grèves internes ou externes, défaillances ou
      pannes internes ou externes telles que les défaillances liées au réseau téléphonique, au réseau du ou des
      prestataires d’ONOFF TELECOM, et toutes celles émanant de l’opérateur de téléphonie mobile auprès duquel un
      Abonnement Opérateur a été souscrit, et d’une manière générale tout évènement irrésistible et imprévisible ne
      permettant pas le bon fonctionnement de l’Application.
    </p>

    <h3>19. Exécution de bonne foi</h3>

    <p>
      Chacune des parties s’engage à exécuter le Contrat qui les lie dans un esprit de loyauté et de transparence. A ce
      titre, chacune des parties :
    </p>

    <ul>
      <li>
        s’interdit tout comportement susceptible de porter atteinte, directement ou indirectement, à la réputation, à
        l'honneur et/ou à l'image de l’autre partie ;
      </li>

      <li>
        informera promptement l’autre partie de tout changement la concernant et/ou de toute difficulté qu’elle
        rencontre pouvant avoir un impact sur l’exécution du Contrat et ce, afin de tenter de la résoudre ;
      </li>

      <li>
        fera ses meilleurs efforts à l’effet de prendre toutes mesures raisonnables afin modérer ou limiter le préjudice
        qui pourrait lui être causé du fait de l’inexécution du Contrat.
      </li>
    </ul>

    <h3>20. Stipulations diverses</h3>

    <p>
      Le Contrat pourra faire l’objet d’une cession totale ou partielle, à titre onéreux ou gratuit dans le cadre d’une
      opération de transmission universelle du patrimoine, de fusion, de scission, d’apport en société, d’apport partiel
      d’actif, ou de toute autre opération de restructuration, par ONOFF TELECOM, ce qui est d’ores et déjà autorisé par
      l’Entreprise. En application des dispositions de l’article 1216 du Code civil, la cession du Contrat produira ses
      effets à l’égard de l’Entreprise lorsque le contrat conclu entre ONOFF TELECOM et le nouveau bénéficiaire lui est
      notifié ou lorsqu’il en prend acte ; dans cette hypothèse, ONOFF TELECOM ne sera plus tenue solidairement à
      l’exécution du Contrat, ce qui est d’ores-et-déjà accepté par l’Entreprise.
    </p>

    <p>
      Dans l'éventualité où l'une quelconque des stipulations des Conditions d’Utilisation serait déclarée nulle ou sans
      effet de quelque façon et pour quelque motif que ce soit, les parties s'engagent à se concerter pour remédier à la
      cause de nullité constatée, de sorte que, sauf impossibilité, le Contrat poursuive ses effets sans discontinuité.
      La nullité d’une clause des Conditions d’Utilisation n’entraînera pas la nullité des autres clauses ou de
      l’ensemble des Conditions d’Utilisation.
    </p>

    <p>
      Aucun silence gardé par ONOFF TELECOM à faire respecter strictement l’un de ses droits aux termes des Conditions
      d’Utilisation ne saurait être considéré comme une renonciation à une quelconque stipulation des présentes ou
      entraîner la déchéance de ce droit. Par ailleurs, aucune renonciation par ONOFF TELECOM à se prévaloir de l’une
      quelconque des stipulations des Conditions d’Utilisation ou droit résultant des Conditions d’Utilisation n’aura
      d’effet si elle ne fait pas l’objet d’un écrit signé par ONOFF TELECOM. En outre, la renonciation par ONOFF
      TELECOM à se prévaloir de tout droit résultant des Conditions d’Utilisation ou d’une stipulation des Conditions
      d’Utilisation en des circonstances déterminées ne vaudra pas renonciation à se prévaloir ultérieurement dudit
      droit ou de ladite stipulation.
    </p>

    <p>
      ONOFF TELECOM accorde à l’Entreprise le droit d’utilisation du nom et de la marque ainsi que de la description
      fonctionnelle et synthétique des services contractualisés dans le cadre des actions de communication de
      l’Entreprise : communiqués de presse, plaquettes marketing, inscription sur les sites web, sous réserve de
      validation de la part d’ONOFF TELECOM. ONOFF TELECOM se réserve le droit de citation de l’Entreprise en référence,
      notamment faire figurer le nom de l’Entreprise, son logo, dans tout support commercial et/ou technique
      (proposition commerciale, rapport de performance…), dans tout support de communication et dans quelque lieu que ce
      soit (PLV, cas client, site web, réseaux sociaux, salons professionnels…)
    </p>

    <p>
      En application des dispositions de l'article 1366 du Code civil, les fichiers, données, messages et registres
      informatisés conservés dans les systèmes informatiques d’ONOFF TELECOM seront admis comme preuve des
      communications et conventions intervenues entre ONOFF TELECOM et l’Entreprise, dans la mesure où la partie dont
      ils émanent puisse être identifiée et qu'ils soient établis et conservés dans des conditions de nature à en
      garantir l'intégrité.
    </p>

    <p>
      Les parties au Contrat sont convenues de supporter le risque de tout changement imprévisible dans des
      circonstances qui rendraient l’exécution des obligations de chacune des parties en vertu du Contrat trop
      onéreuses. Chacune des parties reconnaît expressément que les dispositions de l’article 1195 du Code civil ne sont
      pas applicables au Contrat et qu’elle ne pourra introduire aucune demande de quelque nature et sous quelque forme
      que ce soit, et notamment ayant pour objet de renégocier et/ou de demander aux Tribunaux de réviser ou de mettre
      fin, en application des dispositions de l’article 1195 du Code civil, au Contrat. Toutefois, si un changement de
      circonstances imprévisible lors de la conclusion ou du renouvellement du Contrat rend l’exécution du Contrat
      excessivement onéreuse et dommageable pour une partie qui n’avait pas accepté d’en assumer le risque, celle-ci
      peut notifier à l’autre partie son souhait de solliciter une renégociation du Contrat, cette notification devant
      comporter un exposé des motifs et une copie des justificatifs afférents à sa demande. Chacune des parties devra
      continuer à exécuter ses obligations durant la renégociation étant précisé que la partie destinataire de la
      demande de renégociation ne sera pas tenue de donner une suite favorable à cette demande.
    </p>

    <h3>21. Loi applicable – résolution des litiges</h3>

    <p>
      Les Conditions d’Utilisation et les modalités de fourniture des Services sont soumises au droit français.
    </p>

    <p>
      ONOFF TELECOM met à la disposition de l'Entreprise et des Utilisateurs un service d’assistance accessible via son
      Site Web. Toute réclamation doit être formulée au travers de ce service préalablement à toute action judiciaire.
      ONOFF TELECOM s’engage à apporter une réponse à toute contestation ou demande de remboursement sous trente (30)
      jours ouvrés à partir de la date de réception de celle-ci.
    </p>

    <p>
      En cas de litige découlant de l’interprétation ou l’exécution du Contrat, les parties s’efforceront de rechercher
      une solution amiable. Toute Entreprise établie en France est informée qu’elle peut saisir gratuitement le
      Médiateur des communications électroniques sur son site internet :
      { ' ' }
      <a href="www.mediateur-telecom.fr">
        www.mediateur-telecom.fr
      </a>
      { ' ' }
      de tout différend avec
      ONOFF TELECOM.
    </p>

    <p>
      A défaut d’une solution amiable entre les parties dans un délai de trente (30) jours calendaires à compter de la
      réception par la partie destinataire de la demande de conciliation, tout litige concernant la formation, la
      validité, l’interprétation, l’exécution ou la cessation du Contrat relèvera exclusivement de la compétence des
      tribunaux du ressort de la Cour d’appel de PARIS (FRANCE), nonobstant pluralité de défendeurs, intervention
      forcée, notamment appel en garantie, cette attribution de compétence s’appliquant également en matière de référé,
      de procédures d'urgence ou conservatoires ou de requête.
    </p>
  </>
);

export default FrenchText;
